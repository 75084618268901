/** @format */

export default {
	SUPER_ADMIN: "SUPER_ADMIN",
	ADMINISTRADOR: "ADMINISTRADOR",
	DIRECTOR: "DIRECTOR",
	ADMINISTRATIVO: "ADMINISTRATIVO",
	DOCENTE: "DOCENTE",
	ALUMNO: "ALUMNO",
	AGENTE_VENTAS: "AGENTE_VENTAS",
	COORDINACION_ACADEMICA: "COORDINACIÓN ACADÉMICA",
	PROSPECTO: "PROSPECTO",
	GESTOR_PAGOS: "GESTOR PAGOS",
	TUTOR: "TUTOR",
	LIDER_VENTAS:"LIDER_VENTAS",
	CONTROL_ESCOLAR:"CONTROL ESCOLAR",
}
