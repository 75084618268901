import React, { Component, Fragment } from "react";
import { NavLink } from 'react-router-dom';
import EspecialidadService from "services/EspecialidadService";
import ProspectosAdminService from "services/ProspectosAdminService";
import CatalogoDireccionService from "services/CatalogoDireccionService";
import { Link } from 'react-router-dom';
import swal from 'sweetalert2/dist/sweetalert2.js'
import open from "../images/open.png"
import UsuariosService from "services/UsuariosService";
import IconoHome from "../images/icono_home.svg"
import IconoFlechaSig from "../images/icono_flecha_sig.svg"
import ImgCargando from "../images/icono_cargando.svg"
import AguilaOk from "../images/aguila_ok.svg"
import AguilaCaida from "../images/aguila_caida.svg"

export default class ListaProspectosAdmin extends Component{
	constructor(props) {
		super(props)
		this.state = {
			lista_prospectos : [],
			modalUpdate : false,
			datosModalEditar : false,
			id_usuario : 0,
			id_prospecto : 0,
			nombre : "",
			apellido_paterno : "",
			apellido_materno : "",
			curp : "",
			rfc : "",
			fecha_nacimiento : "",
			sexo : "",
			nacionalidad : "",
			estado : 0,
			municipio : 0,
			localidad : 0,
			municipio_texto : "",
			localidad_texto : "",
			telefono1 : "",
			telefono2 : "",
			correo : "",
			status : 0,
			status_texto : "",
			procedencia_tipo : "",
			escuela_procedencia : "",
			promedio : "",
			anio_egreso : "",
			opcion_estudio : 0,
			opcion_estudio_texto : "",
			dni : "",
			errors : {},
			option_nac_estado : [],
			option_nac_municipio : [],
			option_nac_localidad : [],
			option_tipo_sistema_prospecto  : [],
			option_especialidad : [],
			option_status : [],
			bloquearBotones : false,
			htmlPaginacion : "",
			datoBuscar: ""
		}
		this.styleInputBuscar = {"margin": "2px 10px 0px 10px", "padding": "5px 16px", "borderRadius":"1rem", "outline": "none", "border": "2px solid #b3b4b5", "background": "#e8eaec" }
		this.styleInputTransparente = { "background": "transparent", "border" : "transparent", }
		this.styleErrorCampos = { "fontWeigth" : "bold", "color": "#dc3545" };
		this.registroSiguiente = 0;
		this.registrosPorPagina = 50;
		this.registrosTotales = 0;
		this.paginaActual = 1;

	}

	ListaProspectos(registroInicial=0){
		ProspectosAdminService.ListaProspectos({"registroInicial":registroInicial, "registrosPorPagina":this.registrosPorPagina,"datoBuscar":this.state.datoBuscar}).then((res)=>{
			this.setState({lista_prospectos:res.data[0]});
			this.registrosTotales = res.data[1]["total_registros"];
			this.registroSiguiente = registroInicial;
			this.paginacionTabla();
		});
	}
	ListaEstado(){ CatalogoDireccionService.getEstados().then(res => { this.setState({ option_nac_estado: res.data }); }) }
	ListaMunicipios(id){ CatalogoDireccionService.getMunicipios(id).then(res => { this.setState({option_nac_municipio: res.data }); }) }
	ListaLocalidades(id_estado, id_municipio){ CatalogoDireccionService.getLocalidades(id_estado, id_municipio).then((res) => { this.setState({ option_nac_localidad: res.data }); } ) }
	async getListaEspecialidad() { this.setState({option_especialidad: await EspecialidadService.ListarCarreras().then(response => response.data).catch(error => {return []})})}
	async getListSistemaEstudioProspecto() { this.setState({ option_tipo_sistema_prospecto: await UsuariosService.ListaSistemaEstudiosProspecto() .then(response => response.data) .catch(error => { return [] }) }); }
	async getListStatusUsuario() { this.setState({ option_status: await UsuariosService.ListaStatusUsuario() .then(response => response.data) .catch(error => { return [] }) }); }


	componentDidMount() {
		this.ListaEstado();
		this.getListaEspecialidad();
		this.getListSistemaEstudioProspecto();
		this.getListStatusUsuario();
		this.ListaProspectos();
	}

	asignarValor =(event)=>{ this.setState({ [event.target.name] : event.target.value }); }

	ModalUpdateOb (id, tipo) { 
		this.setState({datosModalEditar:(tipo == "editar" ? true : false)});
		this.setState({errors : {}});
		this.ListaEstado();
		ProspectosAdminService.ProspectoById(id).then((res)=>{
			try {
				let prospecto=res.data[0];
				this.setState({municipio_texto: res.data[1] });
				this.setState({localidad_texto: res.data[2] });
				this.setState({opcion_estudio_texto: res.data[3] });
				this.setState({status_texto: (prospecto.status == 1 ? "Activo" : (prospecto.status == 2 ? "Inactivo" : res.data[4])) });
				this.setState({
					id_usuario : prospecto.id_usuario,
					id_prospecto : prospecto.id_prospecto,
					nombre : prospecto.nombre,
					apellido_paterno : prospecto.apellido_paterno,
					apellido_materno : prospecto.apellido_materno,
					curp : prospecto.curp,
					rfc : prospecto.rfc,
					fecha_nacimiento : prospecto.fecha_nacimiento,
					sexo : prospecto.sexo,
					nacionalidad : prospecto.nacionalidad,
					estado : prospecto.estado,
					municipio : prospecto.municipio,
					localidad : prospecto.localidad,
					telefono1 : prospecto.telefono1,
					telefono2 : prospecto.telefono2,
					correo : prospecto.correo,
					status : prospecto.status,
					procedencia_tipo : prospecto.procedencia_tipo,
					escuela_procedencia : prospecto.escuela_procedencia,
					promedio : prospecto.promedio,
					anio_egreso : prospecto.anio_egreso,
					opcion_estudio : prospecto.opcion_estudio,
					dni : prospecto.dni,
				});
				
				this.ListaMunicipios(this.state.estado);
				this.ListaLocalidades(this.state.estado, this.state.municipio);

				this.setState({modalUpdate:true});
			} catch (error) {
				swal.fire({
					imageUrl:AguilaCaida,
					title: 'Error',
					text: 'No hay información que mostrar',
					allowOutsideClick: false,
					allowEscapeKey: false
				});
			}
		});
	}

	ActualizarDatos = async(e)=>{
        e.preventDefault();

		this.setState({bloquearBotones : true});
		this.blurNombre();
		this.blurApellidoPat();
		this.blurApellidoMat();
		this.blurCurp();
		this.blurDni();
		this.blurRfc();
		this.blurNacionalidad();
		this.blurSexo();
		this.blurFNac();
		this.blurNacEstado();
		this.blurNacMunicipio();
		this.blurNacLocalidad();
		this.blurTelefono1();
		this.blurTelefono2();
		this.blurCorreo();
		this.blurTipoProcedencia();
		this.blurEscuelaProcedencia();
		this.blurPromedio();
		this.blurAnioEgreso();
		this.blurOpcionesEstudio();

		let boolExistCorreo = false;
		if (this.state.correo !== ""){
			boolExistCorreo = await UsuariosService.VerificarCorreoRegistradoAct(this.state.id_usuario, this.state.correo).then(response => response.data).catch(error => { return true });
			if (boolExistCorreo){
				this.state.errors.correo = "Correo electrónico ya esta registrado";
				if (document.getElementById("msjErrorEmail")!=undefined){
					document.getElementById("msjErrorEmail").innerHTML = "<p style=' fontWeigth : bold; color: #dc3545; '>"+this.state.errors.correo+"</p>";
				}
			} else {
				this.state.errors.correo = "";
				delete this.state.errors.correo;
				if (document.getElementById("msjErrorEmail")!=undefined){
					document.getElementById("msjErrorEmail").innerHTML = "";
				}
			}
		}
		
		if (!boolExistCorreo){
			if (Object.keys(this.state.errors).length === 0){
				const {
					lista_prospectos,
					modalUpdate,
					datosModalEditar,
					municipio_texto,
					localidad_texto,
					opcion_estudio_texto,
					errors,
					option_nac_estado,
					option_nac_municipio,
					option_nac_localidad,
					option_tipo_sistema_prospecto,
					option_especialidad,
					option_status,
					status,
					...datosActualizados
				} = this.state;
				ProspectosAdminService.updateProspecto({...datosActualizados}).then((res)=>{
					if(res.data===1){
						swal.fire({
							imageUrl:AguilaOk,
							title: 'Success',
							text: 'Dato actualizado correctamente',
							allowOutsideClick: false,
							allowEscapeKey: false
						});
						this.setState({modalUpdate:!this.state.modalUpdate});
						this.componentDidMount();
					} else {
						swal.fire({
							imageUrl:AguilaCaida,
							title: 'Error',
							text: 'Error al actualizar los datos',
							allowOutsideClick: false,
							allowEscapeKey: false
						});
					}
					this.setState({bloquearBotones : false});
				}).catch(error => {
					swal.fire({
						text: "No se pudo actualizar los datos en la base de datos",
						icon: "error",
						allowOutsideClick: false,
						allowEscapeKey: false
					})
					this.setState({bloquearBotones : false});
				});
			} else { this.setState({bloquearBotones : false}); }
		} else { this.setState({bloquearBotones : false}); }
    }

	asignarValor =(event)=>{ this.setState({ [event.target.name] : event.target.value }); }

	blurNombre =()=>{
		let expReg = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
		if (!this.state.nombre.trim()){
			this.state.errors.nombre = "Nombre requerido";
		} else if (!expReg.test(this.state.nombre.trim())) {
			this.state.errors.nombre = "Nombre no valido";
		} else {
			this.state.errors.nombre = "";
			delete this.state.errors.nombre;
		}
	}

	blurApellidoPat =()=>{
		let expReg = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
		if (!this.state.apellido_paterno.trim()){
			this.state.errors.apellido_paterno = "Apellido paterno requerido";
		} else if (!expReg.test(this.state.apellido_paterno.trim())) {
			this.state.errors.apellido_paterno = "Apellido paterno no valido";
		} else {
			this.state.errors.apellido_paterno = "";
			delete this.state.errors.apellido_paterno;
		}
	}

	blurApellidoMat =()=>{
		let expReg = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
		if (this.state.apellido_materno.trim() != "" && (!expReg.test(this.state.apellido_materno.trim()))) {
			this.state.errors.apellido_materno = "Apellido materno no valido";
		} else {
			this.state.errors.apellido_materno = "";
			delete this.state.errors.apellido_materno;
		}
	}

	blurCurp =()=>{
		let expReg = /^([A-ZÑ&a-zñ]{4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))[MHmh]{1}[A-Za-z]{5}[A-Za-z0-9]{2}$/;
		if (this.state.nacionalidad == "Mexicana" && this.state.curp.trim() != "" && (!expReg.test(this.state.curp.trim()))) {
			this.state.errors.curp = "CURP no valido";
		} else {
			this.state.errors.curp = "";
			delete this.state.errors.curp;
		}
	}

	blurDni =()=>{
		let expReg = /(^([0-9]{8,8}[a-zA-Z]{1})|^)$/;
		if (this.state.nacionalidad == "Extranjera" && this.state.curp.trim() != "" && (!expReg.test(this.state.curp.trim()))) {
			this.state.errors.curp = "DNI no valido";
		} else {
			this.state.errors.curp = "";
			delete this.state.errors.curp;
		}
	}

	blurRfc =()=>{
		let expReg = /^([A-ZÑ&a-zñ]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))[A-ZÑ&a-zñ0-9]{3}$/;
		if (this.state.rfc.trim() != "" && (!expReg.test(this.state.rfc.trim()))) {
			this.state.errors.rfc = "RFC no valido";
		} else {
			this.state.errors.rfc = "";
			delete this.state.errors.rfc;
		}
	}

	blurNacionalidad =()=>{
		if (!(this.state.nacionalidad.trim() && this.state.nacionalidad.trim() != "" && this.state.nacionalidad.trim() != "---")){
			this.state.errors.nacionalidad = "Nacionalidad requerida";
		} else {
			this.state.errors.nacionalidad = "";
			delete this.state.errors.nacionalidad;
		}
	}

	blurSexo =()=>{
		if (!this.state.sexo.trim() || this.state.sexo.trim() == "---"){
			this.state.errors.sexo = "Sexo requerido";
		} else {
			this.state.errors.sexo = "";
			delete this.state.errors.sexo;
		}
	}

	blurFNac =()=>{
		let expReg = /^(([0-9]{4})-(0[1-9]|1[0-2])-(0[1-9]|1[0-9]|2[0-9]|3[0-1]))$/;
		if (!this.state.fecha_nacimiento.trim()){
			this.state.errors.fecha_nacimiento = "Fecha de nacimiento requerida";
		} else if (this.state.fecha_nacimiento.trim() != "" && (!expReg.test(this.state.fecha_nacimiento.trim()))) {
			this.state.errors.fecha_nacimiento = "Fecha de nacimiento no valida";
		} else {
			var arrayFecha = this.state.fecha_nacimiento.trim().split("-");
			if (arrayFecha.length==3){
				const fh2_ = new Date();
				fh2_.setDate(fh2_.getDate()-365);
				const fh = new Date(fh2_.getFullYear(), (fh2_.getMonth()+1), fh2_.getDate());
				const fn = new Date(arrayFecha[0], arrayFecha[1], arrayFecha[2]);
				if (fn < fh){
					this.state.errors.fecha_nacimiento = "";
					delete this.state.errors.fecha_nacimiento;
				} else {
					this.state.errors.fecha_nacimiento = "Fecha de nacimiento no valida";
				}
			} else {
				this.state.errors.fecha_nacimiento = "Fecha de nacimiento no valida";
			}
		}
	}

	blurNacEstado =()=>{
		if (parseInt(this.state.estado) === 0){
			this.state.errors.estado = "Estado requerido";
		} else {
			this.state.errors.estado = "";
			delete this.state.errors.estado;
		}
	}

	blurNacMunicipio =()=>{
		if (parseInt(this.state.estado) !== 88 && parseInt(this.state.estado) !== 99 && parseInt(this.state.estado) !== 100){
			if (parseInt(this.state.municipio) === 0){
				this.state.errors.municipio = "Municipio requerido";
			} else {
				this.state.errors.municipio = "";
				delete this.state.errors.municipio;
			}
		} else {
			this.state.errors.municipio = "";
			delete this.state.errors.municipio;
		}
	}

	blurNacLocalidad =()=>{
		if (parseInt(this.state.estado) !== 88 && parseInt(this.state.estado) !== 99 && parseInt(this.state.estado) !== 100){
			if (parseInt(this.state.localidad) === 0){
				this.state.errors.localidad = "Localidad requerida";
			} else {
				this.state.errors.localidad = "";
				delete this.state.errors.localidad;
			}
		} else {
			this.state.errors.localidad = "";
			delete this.state.errors.localidad;
		}
	}

	blurTelefono1 =()=>{
		let expReg = /^([+]{0,1}[0-9]{10,12})$/;
		if (!this.state.telefono1.trim()){
			this.state.errors.telefono1 = "Telefono requerido";
		} else if (this.state.telefono1.trim() != "" && (!expReg.test(this.state.telefono1.trim()))) {
			this.state.errors.telefono1 = "Telefono no valido";
		} else {
			this.state.errors.telefono1 = "";
			delete this.state.errors.telefono1;
		}
	}

	blurTelefono2 =()=>{
		let expReg = /^([+]{0,1}[0-9]{10,12})$/;
		if (this.state.telefono2.trim() != "" && (!expReg.test(this.state.telefono2.trim()))) {
			this.state.errors.telefono2 = "Telefono no valido";
		} else {
			this.state.errors.telefono2 = "";
			delete this.state.errors.telefono2;
		}
	}

	blurCorreo =()=>{
		let expReg = /^(\w+[/./-]?){1,}@[a-z0-9.-]+[/.]\w{2,4}$/;
		if (!this.state.correo.trim()){
			this.state.errors.correo = "Correo electrónico requerido";
		} else if (this.state.correo.trim() != "" && (!expReg.test(this.state.correo.trim()))) {
			this.state.errors.correo = "Correo electrónico no valido";
		} else {
			this.state.errors.correo = "";
			delete this.state.errors.correo;
		}
	}

	blurPassword =()=>{
		if (!this.state.password.trim()){
			this.state.errors.password = "Password requerido";
		} else {
			this.state.errors.password = "";
			delete this.state.errors.password;
		}
	}

	blurVerificarExistenciaCorreoAct = async () => {
		if (this.state.correo != ""){
			let boolExistCorreo = false;
			boolExistCorreo = await UsuariosService.VerificarCorreoRegistradoAct(this.state.id_usuario, this.state.correo).then(response => response.data).catch(error => { return true });
			if (boolExistCorreo){
				this.state.errors.correo = "Correo electrónico ya esta registrado";
				document.getElementById("msjErrorEmail").innerHTML = "<p style=' fontWeigth : bold; color: #dc3545; '>"+this.state.errors.correo+"</p>";
			} else {
				this.state.errors.correo = "";
				delete this.state.errors.correo;
				document.getElementById("msjErrorEmail").innerHTML = "";
			}
		}
	}

	blurTipoProcedencia =()=>{
		let expReg = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
		if (!this.state.procedencia_tipo.trim()){
			this.state.errors.procedencia_tipo = "Tipo de sistema estudiado requerido";
		} else if (this.state.procedencia_tipo.trim() != "" && (!expReg.test(this.state.procedencia_tipo.trim()))) {
			this.state.errors.procedencia_tipo = "Tipo de sistema estudiado no valido";
		} else {
			this.state.errors.procedencia_tipo = "";
			delete this.state.errors.procedencia_tipo;
		}
	}
	
	blurEscuelaProcedencia =()=>{
		let expReg = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
		if (!this.state.escuela_procedencia.trim()){
			this.state.errors.escuela_procedencia = "Escuela de procedencia requerida";
		} else if (this.state.escuela_procedencia.trim() != "" && (!expReg.test(this.state.escuela_procedencia.trim()))) {
			this.state.errors.escuela_procedencia = "Escuela de procedencia no valida";
		} else {
			this.state.errors.escuela_procedencia = "";
			delete this.state.errors.escuela_procedencia;
		}
	}

	blurPromedio =()=>{
		let expReg = /^[0-9.]{1,4}$/;
		if (!this.state.promedio.trim()){
			this.state.errors.promedio = "Promedio requerido";
		} else if (this.state.promedio.trim() != "" && (!expReg.test(this.state.promedio.trim()))) {
			this.state.errors.promedio = "Promedio no valido";
		} else {
			this.state.errors.promedio = "";
			delete this.state.errors.promedio;
		}
		
	}

	blurAnioEgreso =()=>{
		let expReg = /^[0-9]{4}$/;
		if (!this.state.anio_egreso.trim()){
			this.state.errors.anio_egreso = "Año de egreso requerido";
		} else if (this.state.anio_egreso.trim() != "" && (!expReg.test(this.state.anio_egreso.trim()))) {
			this.state.errors.anio_egreso = "Año de egreso no valido";
		} else {
			this.state.errors.anio_egreso = "";
			delete this.state.errors.anio_egreso;
		}
	}

	blurOpcionesEstudio =()=>{
		if (this.state.opcion_estudio == 0){
			this.state.errors.opcion_estudio = "Opcion de estudio requerido";
		} else {
			this.state.errors.opcion_estudio = "";
			delete this.state.errors.opcion_estudio;
		}
	}

	putDataSelects = event => {
		let selectIndex = event.target.selectedIndex
		let selectName = event.target.name
		let optionValue = event.target.options[selectIndex].value
		switch (selectName) {
			case "estado":
				if (parseInt(optionValue) !== 0 && parseInt(optionValue) !== 88 && parseInt(optionValue) !== 99 && parseInt(optionValue) !== 100){
					this.setState({ municipio : 0 });
					this.setState({ option_nac_municipio : [] });
					this.setState({ localidad : 0 });
					this.setState({ option_nac_localidad : [] });
					this.ListaMunicipios(optionValue);
				} else {
					document.getElementById("msjErrorMunicipio").innerHTML = "";
					document.getElementById("msjErrorLocalidad").innerHTML = "";
					this.setState({ municipio : 0 });
					this.setState({ option_nac_municipio : [] });
					this.setState({ localidad : 0 });
					this.setState({ option_nac_localidad : [] });
				}
				break
			case "municipio":
				this.setState({ localidad : 0 });
				this.setState({ option_nac_localidad : [] });
				this.ListaLocalidades(this.state.estado, optionValue)
				break
			default:
				break
		}
		this.asignarValor(event);
	}

	eliminarProspecto =(id, nombre)=>{
		swal.fire({
			title: "Eliminar prospecto",
			text: "¿Realmente quiere eliminar el prospecto '"+nombre+"'?",
			showCancelButton: true,
			confirmButtonText: "Eliminar",
			cancelButtonText: "Cancelar",
			allowOutsideClick: true,
			allowEscapeKey: true
		}).then(resultado => {
			if (resultado.value) {
				ProspectosAdminService.EliminarProspecto(id).then((res)=>{
					if(res.data){
						swal.fire({
							imageUrl:AguilaOk,
							title: 'Eliminado',
							text: 'Registro eliminado correctamente',
							allowOutsideClick: true,
							allowEscapeKey: true
						});
						this.componentDidMount();
					} else {
						swal.fire({
							imageUrl:AguilaCaida,
							title: 'Error',
							text: 'Error al eliminar el registro',
							allowOutsideClick: true,
							allowEscapeKey: true
						});
					}
				});
			}
		});
	}

	paginacionTabla =()=>{
		let htmlPaginacion = (
			<div className='flex items-center justify-end pt-2 pb-2 border-t border-solid border-blueGray-200 rounded-b'>
				<p className="font-bold mr-3">Página {this.paginaActual} de {(Math.floor(parseInt(this.registrosTotales)/parseInt(this.registrosPorPagina))+1)}</p>
				{(parseInt(this.registroSiguiente)-parseInt(this.registrosPorPagina))>=0 && (
					<button className='bg-emerald-500 text-black active:bg-gray-600 font-bold text-sm px-6  rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 p-2 text-white' type='button' onClick={()=>{this.ListaProspectos(0); this.paginaActual=1; }} style={{"background": "#18253F"}} >Primero</button>
				)}
				{(parseInt(this.registroSiguiente)-parseInt(this.registrosPorPagina))>=0 && (
					<button className='bg-emerald-500 text-black active:bg-gray-600 font-bold text-sm px-6  rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 p-2 text-white' type='button' onClick={()=>{ let numAnt = (parseInt(this.registroSiguiente)-parseInt(this.registrosPorPagina)); this.ListaProspectos(numAnt>0?numAnt:0); this.paginaActual=parseInt(this.paginaActual)-1 }} style={{"background": "#18253F"}} >{"<"}</button>
				)}
				{parseInt(this.registroSiguiente)+parseInt(this.registrosPorPagina)<=(parseInt(this.registrosTotales)) && (
					<button className='bg-emerald-500 text-black active:bg-gray-600 font-bold text-sm px-6  rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 p-2 text-white' type='button' onClick={()=>{ this.ListaProspectos(parseInt(this.registroSiguiente)+parseInt(this.registrosPorPagina)); this.paginaActual=parseInt(this.paginaActual)+1; }} style={{"background": "#18253F"}}>{">"}</button>
				)}
				{parseInt(this.registroSiguiente)+parseInt(this.registrosPorPagina)<=(parseInt(this.registrosTotales)) && (
					<button className='bg-emerald-500 text-black active:bg-gray-600 font-bold text-sm px-6  rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 p-2 text-white' type='button' onClick={()=>{ this.ListaProspectos(((Math.floor(parseInt(this.registrosTotales)/parseInt(this.registrosPorPagina)))*parseInt(this.registrosPorPagina))); this.paginaActual=(Math.floor(parseInt(this.registrosTotales)/parseInt(this.registrosPorPagina))+1); }} style={{"background": "#18253F"}}>Último</button>
				)}
			</div>
		);
		this.setState({htmlPaginacion:(parseInt(this.registrosPorPagina)<parseInt(this.registrosTotales) ? htmlPaginacion : "")});
	}

	render() {
		return (
			<>
				<div className="bg-primary_2 text-white h-auto pb-20">
					<Fragment>

					<div className="w-full bg-primary_2 p-4 sm:p-6 overflow-hidden h-20 z-10">
						<div className="relative text-center">
							<h1 className="text-2xl md:text-3xl text-white font-bold mb-1 text-center font-barlow tracking-widest">
							Gestión de prospecto
							</h1>
						</div>
					</div>

					<div className=" w-full mt-5 h-24 z-10 bg-primary_2 grid grid-cols-1 md:grid-cols-12 ">
						<div className="col-span-5 lg:col-span-6 w-full flex items-center">
							<nav class="flex relative" aria-label="Breadcrumb">
								<ol class="flex items-center pl-10">
									<li class="inline-flex items-center">
										<Link to="/app" className="inline-flex items-center text-sm text-white dark:text-white "> 
											<svg class="w-4 h-4 text-white group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
											<a class="ml-1 text-sm font-bold md:ml-2 dark:text-gray-400 font-barlow tracking-widest text-white">Home</a>
										</Link>
									</li>
									<li>
										<div class="flex items-center">
											<Link to="/app/ListaUsuarios" className="inline-flex items-center text-sm text-white dark:text-white ">
												<svg class="w-6 h-6 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
												<a onClick={this.goBack} class="ml-1 text-sm font-bold md:ml-2 dark:text-gray-400 font-barlow tracking-widest text-white">Usuarios</a>
											</Link>
										</div>
									</li>
									<li aria-current="page">
										<div class="flex items-center">
											<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
											<span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">Gestión de prospecto</span>
										</div>
									</li>
									
								</ol>
							</nav>
						</div>

					</div>
				
						<div className="flex justify-start ">
							<div class="xl:w-96 ">
								<div class="input-group relative flex flex-wrap items-stretch w-full mb-4 ">
								<input 
									type="search" 
									class="form-control relative flex-auto px-3 py-1.5 text-base font-normal text-black bg-primary_2 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-black focus:bg-white focus:border-secondary focus:outline-none" 
									placeholder="Buscar..." 
									name="datoBuscar"
									value={this.state.datoBuscar} 
									onChange={event => this.asignarValor(event)} onKeyPress={e => { if (e.key=="Enter"){ this.ListaProspectos(); } }}/>

									<button 
										onClick={()=>{ this.ListaProspectos(); }}
										><svg class="h-6 w-6 text-white ml-2 mt-5 "  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="10" cy="10" r="7" />  <line x1="21" y1="21" x2="15" y2="15" /></svg>
									</button>
								</div>
							</div>
						</div>

						<div className='flex flex-row-reverse mr-5 mb-5'>
							<NavLink to={{ pathname: '/app/FormUsuario', state: { idTipoUsuarioSelect: 7 } }} rel="noopener noreferrer" className='inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-6 py-3 bg-color1 hover:bg-blue focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110 h-9'>
								<p className='text-sm font-medium leading-none text-white'><i className='fas fa-plus-circle'></i> Agregar prospecto</p>
							</NavLink>
						</div>

			
					
					<div className="grid grid-cols-1 md:grid-cols-2 gap-10 lg:gap-0 lg:block">
							<div className="hidden lg:block col-span-12 xl:col-span-12 w-full bg-primary_2">
								<div className="w-full text-sm grid grid-cols-12 text-gray-800 font-semibold">


								<div className="font-semibold text-center col-span-2 ml-3 px-6 py-3">
										<div className="flex justify-start items-center">
											<div>
												<label className="text-lg font-barlow tracking-widest" htmlFor="">Nombre </label><br />
											</div>
										</div>
									</div>


									<div className="font-semibold col-span-1 ml-3 px-6 py-3">
										<div className="flex">
											<div>
												<label className="text-lg font-barlow tracking-widest" htmlFor="">Nacionalidad</label><br />
											</div>
										</div>
									</div>

								<div className="font-semibold text-center col-span-2 ml-10 px-6 py-3 text-white">
									<div className="flex justify-center items-center ">
										<div>
											<label className="text-lg font-barlow tracking-widest" htmlFor="">Teléfono</label><br/>
										</div>
									</div>
								</div>
								

								<div className="font-semibold text-center col-span-5 ml-3 px-6 py-3 text-white">
									<div className="flex justify-start items-center">
										<div>
											<label className="text-lg font-barlow tracking-widest" htmlFor="">Correo</label><br/>
										</div>
									</div>
								</div>

	
								{false && <div className="font-semibold text-center col-span-2 ml-3  text-white">
									<div className="flex justify-start items-center">
										<div>
											<label className="text-lg font-barlow tracking-widest" htmlFor="">Status</label><br/>
										</div>
									</div>
								</div>
}

								<div className="font-semibold text-center col-span-2 ml-3  text-white">
									<div className="flex justify-start items-center">
										<div>
											<label className="text-lg font-barlow tracking-widest" htmlFor="">Mostrar</label><br/>
										</div>
									</div>
								</div>

								
							</div>
							{this.state.lista_prospectos.map(prospecto =>
								<div className="w-full flex ">
									<div className="mt-5 rounded-2xl w-10/12 md:w-full lg:grid grid-cols-12 h-auto px-5 py-5 bg-secondary_2 relative">

										<div className="col-span-2 flex justify-start items-center">
											<h1 className="text-white text-left pl-3 md:pl-5 text-lg font-barlow tracking-widest">{prospecto.nombre + " "+prospecto.apellido_paterno + " "+prospecto.apellido_materno}</h1>
										</div>

										<div className="col-span-1 flex justify-start items-start mt-3">
											<h1 className="text-white text-start pl-3 md:pl-5 text-lg font-barlow tracking-widest">{prospecto.nacionalidad}</h1>
										</div>


										<div className="col-span-2 flex justify-center items-center ">
											<h1 className="text-white text-center pl-3 md:pl-5 text-lg font-barlow tracking-widest">{prospecto.telefono1 +(prospecto.telefono2!=""?", "+prospecto.telefono2:"")}</h1>
										</div>

										<div className="col-span-5 flex justify-right items-right mt-3">
											<h1 className="text-white text-right pl-3 md:pl-5 text-lg font-barlow tracking-widest">{prospecto.correo}</h1>
										</div>

										{false && (<td>{(prospecto.id_status!=undefined ? (prospecto.id_status == 1 ? "Activo" : (prospecto.id_status == 2 ? "Inactivo" : prospecto.status)) : "")}</td>)}

										<div className="col-span-2 flex justify-start items-center">
											<div className="flex justify-center items-center">
												<div className="mt-2 ml-6 transform hover:text-purple-500 hover:scale-110">
													<button onClick={() => { this.ModalUpdateOb(prospecto.id_prospecto,"ver"); }} title="Detalles prospecto">
														<svg className="h-7 w-7 text-white" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">  <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />  <circle cx="12" cy="12" r="3" /></svg>
													</button>
												</div>
												<div className="mt-2 ml-2 transform hover:text-purple-500 hover:scale-110">
													<button onClick={() => { this.ModalUpdateOb(prospecto.id_prospecto,"editar"); }} title="Actualizar prospecto">
														<svg className="h-6 w-6 text-green-500" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">  <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />  <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" /></svg>
													</button>
												</div>
												<div className="mt-2 ml-2 transform hover:text-purple-500 hover:scale-110">
													<button onClick={() => {this.eliminarProspecto(prospecto.id_prospecto, prospecto.nombre + " "+prospecto.apellido_paterno + " "+prospecto.apellido_materno); }} title="Eliminar prospecto">
														<svg className="h-8 w-8 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" >
															<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
														</svg>
													</button>
												</div>
											</div>
										</div>

									</div>
								</div>
								
							)}
							{this.state.htmlPaginacion}
						</div>
					</div>
					{this.state.modalUpdate && (
						<Fragment>
							<form onSubmit={this.ActualizarDatos}>
								<div className="overflow-y-hidden text-black">
									<div className='justify-center items-center fixed overflow-auto inset-0 z-50 outline-none focus:outline-none animate__animated animate__fadeIn'>
										<div className='relative w-auto my-6 mx-auto max-w-3xl z-20'>
											<div className='rounded-3xl relative flex flex-col w-full bg-base outline-none focus:outline-none shadow-sm2'>
												<div className='rounded-t-3xl'>
													<h3 className='text-primary text-3xl text-center my-5 font-bold uppercase'>
														{this.state.datosModalEditar?"Actualizar Datos":"Detalles"}
													</h3>
												</div>
												<div className='relative flex-auto'>
													<div className='grid grid-cols-1 gap-5 px-6 py-5  '>
														<div className="shadow-sm2 px-5 py-2 rounded-3xl">
															<div className="grid grid-cols-3 md:grid-cols-3 gap-5 md:gap-8 mt-5 ">
																<div>
																	<label htmlFor="nombre" className='md:text-sm text-xs text-gray-500 text-light font-semibold'>Nombre*</label>
																	<input
																		className={this.state.datosModalEditar?'w-full mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out ':'w-full mt-1 rounded-lg p-2 border outline-none'}
																		name='nombre'
																		type='text'
																		value={this.state.nombre}
																		onChange={event => this.asignarValor(event) }
																		onBlur={event => {this.asignarValor(event); this.blurNombre();}}
																		maxLength={50}
																		style={this.state.datosModalEditar?null:this.styleInputTransparente}
																		readOnly={this.state.datosModalEditar ? "" : "readonly"}
																		/>
																	{(this.state.errors.nombre && this.state.datosModalEditar) && this.state.errors.nombre !="" ? <p style={this.styleErrorCampos}>{this.state.errors.nombre}</p> : <p></p> }
																</div>
																<div >
																	<label htmlFor="apellido_paterno" className='md:text-sm text-xs text-gray-500 text-light font-semibold'>Apellido paterno*</label>
																	<input
																		className={this.state.datosModalEditar?'w-full mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out ':'w-full mt-1 rounded-lg p-2 border outline-none'}
																		name='apellido_paterno'
																		type='text'
																		value={this.state.apellido_paterno}
																		onChange={event => this.asignarValor(event) }
																		onBlur={event => {this.asignarValor(event); this.blurApellidoPat();}}
																		maxLength={50}
																		style={this.state.datosModalEditar?null:this.styleInputTransparente}
																		readOnly={this.state.datosModalEditar ? "" : "readonly"}
																		/>
																	{(this.state.errors.apellido_paterno && this.state.datosModalEditar) && this.state.errors.apellido_paterno !="" ? <p style={this.styleErrorCampos}>{this.state.errors.apellido_paterno}</p> : <p></p> }
																</div>
																<div >
																	<label htmlFor="apellido_materno" className='md:text-sm text-xs text-gray-500 text-light font-semibold'>Apellido materno</label>
																	<input
																		className={this.state.datosModalEditar?'w-full mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out ':'w-full mt-1 rounded-lg p-2 border outline-none'}
																		name='apellido_materno'
																		type='text'
																		value={this.state.apellido_materno}
																		onChange={event => this.asignarValor(event) }
																		onBlur={event => {this.asignarValor(event); this.blurApellidoMat();}}
																		maxLength={50}
																		style={this.state.datosModalEditar?null:this.styleInputTransparente}
																		readOnly={this.state.datosModalEditar ? "" : "readonly"}
																		/>
																	{(this.state.errors.apellido_materno && this.state.datosModalEditar) && this.state.errors.apellido_materno !="" ? <p style={this.styleErrorCampos}>{this.state.errors.apellido_materno}</p> : <p></p> }
																</div>
															</div>
															<div className="grid grid-cols-3 md:grid-cols-3 gap-5 md:gap-8 mt-5 ">
																<div>
																	<label htmlFor="nacionalidad" className='md:text-sm text-xs text-gray-500 text-light font-semibold'>Nacionalidad*</label>
																	{this.state.datosModalEditar ? (
																		<select
																			className={this.state.datosModalEditar?'w-full mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out bg-white':'w-full mt-1 rounded-lg p-2 border outline-none'}
																			value={this.state.nacionalidad}
																			type='text'
																			onChange={event => { this.asignarValor(event); this.setState({ curp : "" }); delete this.state.errors.curp; }}
																			onBlur={event => {this.asignarValor(event); this.blurNacionalidad(); }}
																			name='nacionalidad'
																			maxLength={15}
																			style={this.state.datosModalEditar?null:this.styleInputTransparente}
																			readOnly={this.state.datosModalEditar ? "" : "readonly"}
																			>
																			<option>---</option>
																			<option value='Mexicana'>Mexicana</option>
																			<option value='Extranjera'>Extranjera</option>
																		</select>
																	):(
																		<input
																		className='w-full mt-1 rounded-lg p-2 border outline-none'
																		type='text'
																		value={this.state.apellido_materno}
																		style={this.state.datosModalEditar?null:this.styleInputTransparente}
																		readOnly={this.state.datosModalEditar ? "" : "readonly"}
																		/>
																	)}
																	{(this.state.errors.nacionalidad && this.state.datosModalEditar) && this.state.errors.nacionalidad !="" ? <p style={this.styleErrorCampos}>{this.state.errors.nacionalidad}</p> : <p></p> }
																</div>
																<div>
																	<label htmlFor="fecha_nacimiento" className='md:text-sm text-xs text-gray-500 text-light font-semibold'>Fecha de nacimiento*</label>
																	<input
																		className={this.state.datosModalEditar?'w-full mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out':'w-full mt-1 rounded-lg p-2 border outline-none'}
																		style={this.state.datosModalEditar?null:this.styleInputTransparente}
																		name='fecha_nacimiento'
																		value={this.state.fecha_nacimiento}
																		type='date'
																		onChange={event => this.asignarValor(event) }
																		onBlur={event => {this.asignarValor(event); this.blurFNac();}}
																		readOnly={this.state.datosModalEditar ? "" : "readonly"}
																		/>
																	{(this.state.errors.fecha_nacimiento && this.state.datosModalEditar) && this.state.errors.fecha_nacimiento !="" ? <p style={this.styleErrorCampos}>{this.state.errors.fecha_nacimiento}</p> : <p></p> }
																</div>
																{(this.state.nacionalidad == "" || this.state.nacionalidad == "---" || this.state.nacionalidad == "Mexicana")&& (
																	<div>
																		<label htmlFor="curp" className='md:text-sm text-xs text-gray-500 text-light font-semibold'>CURP</label>
																		<input
																			className={this.state.datosModalEditar?'w-full  mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out':'w-full  mt-1 rounded-lg p-2 border outline-none'}
																			style={this.state.datosModalEditar?null:this.styleInputTransparente}
																			name='curp'
																			type='text'
																			maxLength={18}
																			value={this.state.curp}
																			onChange={event => this.asignarValor(event) }
																			onBlur={event => {this.asignarValor(event); this.blurCurp();}}
																			readOnly={this.state.datosModalEditar ? "" : "readonly"}
																			/>
																			{(this.state.errors.curp && this.state.datosModalEditar) && this.state.errors.curp !="" ? <p style={this.styleErrorCampos}>{this.state.errors.curp}</p> : <p></p> }
																	</div>
																)}
																
																{this.state.nacionalidad == "Extranjera" && (
																	<div id="">
																		<span className='w-full'>
																		<label htmlFor="curp" className='md:text-sm text-xs text-gray-500 text-light font-semibold'>DNI</label>
																		<input
																			className={this.state.datosModalEditar?'w-full mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out':'w-full mt-1 rounded-lg p-2 border outline-none'}
																			style={this.state.datosModalEditar?null:this.styleInputTransparente}
																			name='curp'
																			type='text'
																			maxLength={9}
																			value={this.state.curp}
																			onChange={event => this.asignarValor(event) }
																			onBlur={event => {this.asignarValor(event);  this.blurDni();}}
																			readOnly={this.state.datosModalEditar ? "" : "readonly"}
																			/>
																			{(this.state.errors.curp && this.state.datosModalEditar) && this.state.errors.curp !="" ? <p style={this.styleErrorCampos}>{this.state.errors.curp}</p> : <p></p> }
																		</span>
																	</div>
																)}
															</div>
															<div className="grid grid-cols-3 md:grid-cols-3 gap-5 md:gap-8 mt-5 ">
																<div>
																	<label htmlFor="rfc" className='md:text-sm text-xs text-gray-500 text-light font-semibold'>RFC</label>
																	<input
																		className={this.state.datosModalEditar?'w-full mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out':'w-full mt-1 rounded-lg p-2 border outline-none'}
																		style={this.state.datosModalEditar?null:this.styleInputTransparente}
																		name='rfc'
																		type='text'
																		maxLength={13}
																		value={this.state.rfc}
																		onChange={event => this.asignarValor(event) }
																		onBlur={event => {this.asignarValor(event); this.blurRfc();}}
																		readOnly={this.state.datosModalEditar ? "" : "readonly"}
																		/>
																	{(this.state.errors.rfc && this.state.datosModalEditar) && this.state.errors.rfc !="" ? <p style={this.styleErrorCampos}>{this.state.errors.rfc}</p> : <p></p> }
																</div>
																<div>
																	<label htmlFor="sexo" className='md:text-sm text-xs text-gray-500 text-light font-semibold'>Sexo*</label>
																	{this.state.datosModalEditar ? (
																		<select
																			className={this.state.datosModalEditar?'w-full mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out bg-white':'w-full mt-1 rounded-lg p-2 border outline-none'}
																			value={this.state.sexo}
																			onChange={event => { this.asignarValor(event); }}
																			onBlur={event => {this.asignarValor(event); this.blurSexo(); }}
																			name='sexo'
																			maxLength={7}
																			style={this.state.datosModalEditar?null:this.styleInputTransparente}
																			readOnly={this.state.datosModalEditar ? "" : "readonly"}
																			>
																			<option value=''>---</option>
																			<option value='Hombre'>Hombre</option>
																			<option value='Mujer'>Mujer</option>
																		</select>
																	) : (
																		<input
																		className='w-full mt-1 rounded-lg p-2 border outline-none'
																		type='text'
																		value={this.state.sexo}
																		style={this.state.datosModalEditar?null:this.styleInputTransparente}
																		readOnly={this.state.datosModalEditar ? "" : "readonly"}
																		/>
																	)}
																	{(this.state.errors.sexo && this.state.datosModalEditar) && this.state.errors.sexo !="" ? <p style={this.styleErrorCampos}>{this.state.errors.sexo}</p> : <p></p> }
																</div>
																<div>
																	<label htmlFor="estado" className='md:text-sm text-xs text-gray-500 text-light font-semibold'>Estado*</label>
																	{this.state.datosModalEditar ? (
																		<select
																			className={this.state.datosModalEditar?'w-full mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out bg-white':'w-full mt-1 rounded-lg p-2 border outline-none'}
																			style={this.state.datosModalEditar?null:this.styleInputTransparente}
																			value={this.state.estado}
																			onChange={event => { this.putDataSelects(event); }}
																			onBlur={event => {this.asignarValor(event); this.blurNacEstado(); }}
																			name='estado'
																			readOnly={this.state.datosModalEditar ? "" : "readonly"}
																			>
																			{this.state.option_nac_estado.map(element => (
																				<option
																					value={element.id_Estado}
																					key={element.id_Estado}>
																					{element.entidad_Federativa}
																				</option>
																			))}
																		</select>
																	):(
																		<input
																		className='w-full mt-1 rounded-lg p-2 border outline-none'
																		type='text'
																		value={(this.state.option_nac_estado[this.state.estado] != undefined ? this.state.option_nac_estado[this.state.estado]["entidad_Federativa"] : "NO DEFINIDO")}
																		style={this.state.datosModalEditar?null:this.styleInputTransparente}
																		readOnly={this.state.datosModalEditar ? "" : "readonly"}
																		/>
																	)}
																	{(this.state.errors.estado && this.state.datosModalEditar) && this.state.errors.estado !="" ? <p style={this.styleErrorCampos}>{this.state.errors.estado}</p> : <p></p> }
																</div>
															</div>
															<div className="grid grid-cols-3 md:grid-cols-3 gap-5 md:gap-8 mt-5 ">
																<div>
																	<label htmlFor="municipio" className='md:text-sm text-xs text-gray-500 text-light font-semibold'>Municipio*</label>
																	{this.state.datosModalEditar ? (
																		<select
																			className={this.state.datosModalEditar?'w-full mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out bg-white':'w-full mt-1 rounded-lg p-2 border outline-none'}
																			style={this.state.datosModalEditar?null:this.styleInputTransparente}
																			value={this.state.municipio}
																			onChange={event => { this.putDataSelects(event); }}
																			onBlur={event => {this.asignarValor(event); this.blurNacMunicipio(); }}
																			name='municipio'
																			readOnly={this.state.datosModalEditar ? "" : "readonly"}
																			>
																			<option key={0} value={0}>NO ESPECIFICADO</option>
																			{
																				parseInt(this.state.estado) !== 88 && parseInt(this.state.estado) !== 99 && parseInt(this.state.estado) !== 100 ? 
																				this.state.option_nac_municipio.map(element => (
																					<option
																						value={element.c_mnpio}
																						key={element.c_mnpio}>
																						{element.nombre_Municipio}
																					</option>
																				)) : ""
																			}
																		</select>
																	):(
																		<input
																		className='w-full mt-1 rounded-lg p-2 border outline-none'
																		type='text'
																		value={this.state.municipio_texto}
																		style={this.state.datosModalEditar?null:this.styleInputTransparente}
																		readOnly={this.state.datosModalEditar ? "" : "readonly"}
																		/>
																	)}
																	<div id="msjErrorMunicipio">
																		{(this.state.errors.municipio && this.state.datosModalEditar) && this.state.errors.municipio !="" ? <p style={this.styleErrorCampos}>{this.state.errors.municipio}</p> : <p></p> }
																	</div>
																</div>
																<div>
																	<label htmlFor="localidad" className='md:text-sm text-xs text-gray-500 text-light font-semibold'>Localidad*</label>
																	{this.state.datosModalEditar ? (
																		<select
																			className={this.state.datosModalEditar?'w-full mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out bg-white':'w-full mt-1 rounded-lg p-2 border outline-none'}
																			style={this.state.datosModalEditar?null:this.styleInputTransparente}
																			value={this.state.localidad}
																			onChange={event => { this.putDataSelects(event); }}
																			onBlur={event => {this.asignarValor(event); this.blurNacLocalidad(); }}
																			name='localidad'
																			readOnly={this.state.datosModalEditar ? "" : "readonly"}
																			>
																			<option key={0} value={0}>NO ESPECIFICADO</option>
																			{
																				parseInt(this.state.nac_estado) !== 88 && parseInt(this.state.nac_estado) !== 99 && parseInt(this.state.nac_estado) !== 100 ? 
																					this.state.option_nac_localidad.map(element => (
																					<option
																						value={element.id_Localidad}
																						key={element.id_Localidad}>
																						{element.nombre}
																					</option>
																				)) : ""
																			}
																		</select>
																	):(
																		<input
																		className='w-full mt-1 rounded-lg p-2 border outline-none'
																		type='text'
																		value={this.state.localidad_texto}
																		style={this.state.datosModalEditar?null:this.styleInputTransparente}
																		readOnly={this.state.datosModalEditar ? "" : "readonly"}
																		/>
																	)}
																	<div id="msjErrorLocalidad">
																		{(this.state.errors.localidad && this.state.datosModalEditar) && this.state.errors.localidad !="" ? <p style={this.styleErrorCampos}>{this.state.errors.localidad}</p> : <p></p> }
																	</div>
																</div>
																<div>
																	<label htmlFor="telefono1" className='md:text-sm text-xs text-gray-500 text-light font-semibold'>Telefono celular</label>
																	<input
																		className={this.state.datosModalEditar?'w-full mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out':'w-full mt-1 rounded-lg p-2 border outline-none'}
																		style={this.state.datosModalEditar?null:this.styleInputTransparente}
																		name='telefono1'
																		type='tel'
																		maxLength={12}
																		value={this.state.telefono1}
																		onChange={event => this.asignarValor(event) }
																		onBlur={event => {this.asignarValor(event); this.blurTelefono1();}}
																		readOnly={this.state.datosModalEditar ? "" : "readonly"}
																		/>
																	{(this.state.errors.telefono1 && this.state.datosModalEditar) && this.state.errors.telefono1 !="" ? <p style={this.styleErrorCampos}>{this.state.errors.telefono1}</p> : <p></p> }
																</div>
															</div>
															<div className="grid grid-cols-3 md:grid-cols-3 gap-5 md:gap-8 mt-5 ">
																<div>
																	<label htmlFor="telefono2" className='md:text-sm text-xs text-gray-500 text-light font-semibold'>Telefono fijo</label>
																	<input
																		className={this.state.datosModalEditar?'w-full mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out':'w-full mt-1 rounded-lg p-2 border outline-none'}
																		style={this.state.datosModalEditar?null:this.styleInputTransparente}
																		name='telefono2'
																		type='tel'
																		maxLength={12}
																		value={this.state.telefono2}
																		onChange={event => this.asignarValor(event) }
																		onBlur={event => {this.asignarValor(event); this.blurTelefono2();}}
																		readOnly={this.state.datosModalEditar ? "" : "readonly"}
																		/>
																	{(this.state.errors.telefono2 && this.state.datosModalEditar) && this.state.errors.telefono2 !="" ? <p style={this.styleErrorCampos}>{this.state.errors.telefono2}</p> : <p></p> }
																</div>
																<div>
																	<label htmlFor="correo" className='md:text-sm text-xs text-gray-500 text-light font-semibold'>Correo*</label>
																	<input
																		className={this.state.datosModalEditar?'w-full mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out':'w-full mt-1 rounded-lg p-2 border outline-none'}
																		style={this.state.datosModalEditar?null:this.styleInputTransparente}
																		name='correo'
																		type='email'
																		maxLength={100}
																		value={this.state.correo}
																		onChange={event => this.asignarValor(event) }
																		onBlur={event => {this.asignarValor(event); this.blurCorreo(); this.blurVerificarExistenciaCorreoAct(); }}
																		readOnly={this.state.datosModalEditar ? "" : "readonly"}
																		/>
																		<div id="msjErrorEmail">
																			{(this.state.errors.correo && this.state.datosModalEditar) && this.state.errors.correo !="" ? <p style={this.styleErrorCampos}>{this.state.errors.correo}</p> : <p></p> }
																		</div>
																</div>
																<div>
																	<label htmlFor="procedencia_tipo" className='md:text-sm text-xs text-gray-500 text-light font-semibold'>Tipo de sistema estudiado*</label>
																	<input
																		className={this.state.datosModalEditar?'w-full mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out flecha_select':'w-full mt-1 rounded-lg p-2 border outline-none flecha_select'}
																		style={this.state.datosModalEditar?null:this.styleInputTransparente}
																		name='procedencia_tipo'
																		type='text'
																		maxLength={100}
																		value={this.state.procedencia_tipo}
																		onChange={event => this.asignarValor(event) }
																		onBlur={event => {this.asignarValor(event); this.blurTipoProcedencia();}}
																		list="listaSistemasEstudiosProspecto"
																		readOnly={this.state.datosModalEditar ? "" : "readonly"}
																		/>
																	{this.state.datosModalEditar && (
																		<datalist id="listaSistemasEstudiosProspecto"> {this.state.option_tipo_sistema_prospecto.map(item => { return ( <option key={item.id_sistema} value={item.sistema} /> )}) }</datalist>
																	)}
																	{(this.state.errors.procedencia_tipo && this.state.datosModalEditar) && this.state.errors.procedencia_tipo !="" ? <p style={this.styleErrorCampos}>{this.state.errors.procedencia_tipo}</p> : <p></p> }
																</div>
															</div>
															<div className="grid grid-cols-3 md:grid-cols-3 gap-5 md:gap-8 mt-5 ">
																<div>
																	<label htmlFor="escuela_procedencia" className='md:text-sm text-xs text-gray-500 text-light font-semibold'>Escuela de procedencia*</label>
																	<input
																		className={this.state.datosModalEditar?'w-full mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out':'w-full mt-1 rounded-lg p-2 border outline-none'}
																		style={this.state.datosModalEditar?null:this.styleInputTransparente}
																		name='escuela_procedencia'
																		type='text'
																		maxLength={200}
																		value={this.state.escuela_procedencia}
																		onChange={event => this.asignarValor(event) }
																		onBlur={event => {this.asignarValor(event); this.blurEscuelaProcedencia();}}
																		readOnly={this.state.datosModalEditar ? "" : "readonly"}
																		/>
																	{(this.state.errors.escuela_procedencia && this.state.datosModalEditar) && this.state.errors.escuela_procedencia !="" ? <p style={this.styleErrorCampos}>{this.state.errors.escuela_procedencia}</p> : <p></p> }
																</div>
																<div>
																	<label htmlFor="promedio" className='md:text-sm text-xs text-gray-500 text-light font-semibold'>Promedio*</label>
																	<input
																		className={this.state.datosModalEditar?'w-full mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out':'w-full mt-1 rounded-lg p-2 border outline-none'}
																		style={this.state.datosModalEditar?null:this.styleInputTransparente}
																		name='promedio'
																		type='text'
																		maxLength={4}
																		value={this.state.promedio}
																		onChange={event => this.asignarValor(event) }
																		onBlur={event => {this.asignarValor(event); this.blurPromedio();}}
																		readOnly={this.state.datosModalEditar ? "" : "readonly"}
																		/>
																	{(this.state.errors.promedio && this.state.datosModalEditar) && this.state.errors.promedio !="" ? <p style={this.styleErrorCampos}>{this.state.errors.promedio}</p> : <p></p> }
																</div>
																<div>
																	<label htmlFor="anio_egreso" className='md:text-sm text-xs text-gray-500 text-light font-semibold'>Año de egreso*</label>
																	<input
																		className={this.state.datosModalEditar?'w-full mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out':'w-full mt-1 rounded-lg p-2 border outline-none'}
																		style={this.state.datosModalEditar?null:this.styleInputTransparente}
																		name='anio_egreso'
																		type='text'
																		maxLength={4}
																		value={this.state.anio_egreso}
																		onChange={event => this.asignarValor(event) }
																		onBlur={event => {this.asignarValor(event); this.blurAnioEgreso();}}
																		readOnly={this.state.datosModalEditar ? "" : "readonly"}
																		/>
																	{(this.state.errors.anio_egreso && this.state.datosModalEditar) && this.state.errors.anio_egreso !="" ? <p style={this.styleErrorCampos}>{this.state.errors.anio_egreso}</p> : <p></p> }
																</div>
															</div>
															<div className="grid grid-cols-3 md:grid-cols-3 gap-5 md:gap-8 mt-5 ">
																<div>
																	<label htmlFor="opcion_estudio" className='md:text-sm text-xs text-gray-500 text-light font-semibold'>Opción de estudios*</label>
																	{this.state.datosModalEditar ? (
																		<select
																			className={this.state.datosModalEditar?'w-full mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out bg-white':'w-full mt-1 rounded-lg p-2 border outline-none'}
																			style={this.state.datosModalEditar?null:this.styleInputTransparente}
																			value={this.state.opcion_estudio}
																			onChange={event => { this.asignarValor(event); }}
																			onBlur={event => {this.asignarValor(event); this.blurOpcionesEstudio(); }}
																			name='opcion_estudio'
																			readOnly={this.state.datosModalEditar ? "" : "readonly"}
																			>
																			<option key={0} value={0}>NO ESPECIFICADO</option>
																			{this.state.option_especialidad.map(item => {
																				return (
																					<option
																						key={item.id_especialidad}
																						value={item.id_especialidad}>
																						{item.nombre_especialidad}
																					</option>
																				)
																			})}
																		</select>
																	):(
																		<input
																		className='w-full mt-1 rounded-lg p-2 border outline-none'
																		type='text'
																		value={this.state.opcion_estudio_texto}
																		style={this.state.datosModalEditar?null:this.styleInputTransparente}
																		readOnly={this.state.datosModalEditar ? "" : "readonly"}
																		/>
																	)}
																	{(this.state.errors.opcion_estudio && this.state.datosModalEditar) && this.state.errors.opcion_estudio !="" ? <p style={this.styleErrorCampos}>{this.state.errors.opcion_estudio}</p> : <p></p> }
																</div>
															</div>
														</div>
													</div>
												</div>
												{!this.state.bloquearBotones && (
													<div className='flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b'>
														<button
														className='text-red-500 background-transparent font-bold uppercase px-6 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
														type='button'
														onClick={ ()=> { this.setState({modalUpdate:false}); }} >
														Cerrar
														</button>
														{this.state.datosModalEditar && (
															<button
																className=' bg-emerald-500 text-black active:bg-gray-600 font-bold uppercase text-sm px-6  rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
																type='submit'>
																Actualizar
															</button>
														)}
														{!this.state.datosModalEditar && (
															<a onClick={ (e)=> { this.setState({datosModalEditar : !this.state.datosModalEditar}); }}
																className=' bg-emerald-500 text-black active:bg-gray-600 font-bold uppercase text-sm px-6  rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150' >
																Editar
															</a>
														)}
													</div>
												)}
												{this.state.bloquearBotones && (
													<div className='flex items-center justify-center  md:gap-8 gap-4 pt-5 pb-5 font-bold mb-10 text-2xl'>
														<img className="w-10 h-10" src={ImgCargando} alt=">"/> Guardando informacion ...
													</div>
												)}
											</div>
										</div>
										<div onClick={ ()=> {if (!this.state.datosModalEditar){ this.setState({modalUpdate:false}); } }} className={(!this.state.datosModalEditar?'cursor-pointer':'')+' opacity-25 fixed inset-0 bg-black'} style={{"right":"12px"}}></div>
									</div>
								</div>
							</form>
						</Fragment>
					)}
				</Fragment>
				</div>
			</>
		)
	}
	

}