import axios from 'axios'; 

import {APIURL} from "./Host";
  
class AsignaturasService{

    AsignaturabyEspecialidad(id_especialidad){
        console.log("url "+APIURL + "/asignaturas/"+id_especialidad);
        return axios.get(APIURL + "/asignaturas/"+id_especialidad);
    }
    
    AsignaturabyPlantel(id_plantel){
        console.log("url "+APIURL + "/asignatura_by_plantel/"+id_plantel);
        return axios.get(APIURL + "/asignatura_by_plantel/"+id_plantel);
    }

    ParcialesAsignatura(id_kardex, id_asignatura, id_especialidad){
        console.log("url "+APIURL + "/obtener_calificacion/"+id_kardex+"/"+id_asignatura+"/"+id_especialidad);
        return axios.get(APIURL + "/obtener_calificacion/"+id_kardex+"/"+id_asignatura+"/"+id_especialidad);
    }

    Calificacionbyid(id_asig_especialidad){
        return axios.get(APIURL + "/calificacion/"+id_asig_especialidad);
    }

    CalificacionUpdate(calif_actualizado){
        console.log("datos a enviar ",calif_actualizado);
        return axios.put(APIURL+"/calificacion_actualizar", calif_actualizado);
    }
    NuevaAsignatura(asignatura){
        console.log("nueva asignatura ",asignatura);
        return axios.put(APIURL+"/nueva_asignatura",asignatura);
    }
    AsignaturaById(id_asignatura){
        return axios.get(APIURL+"/asignatura_by_id/"+id_asignatura);
    }

    AsignaturaUpdate(asignatura){
        console.log("asignatura a actualizar ",asignatura);
        return axios.put(APIURL+"/update_asignatura",asignatura);
    }

    AsignaturaActivos(id,plantel){
        console.log("url "+APIURL + "/asignaturas/"+id+"/"+plantel);
        return axios.get(APIURL + "/asignaturas_activos/"+id+"/"+plantel);
    }
    AsignaturaNoAsignadas(id_especialidad, plantel){
        console.log("url "+APIURL + "/asignaturas_no_asignadas/"+id_especialidad);
        return axios.get(APIURL + "/asignaturas_no_asignadas/"+id_especialidad+"/"+plantel);
    }

    AsignaturaTallerByArea(id_area){
        console.log(APIURL + "/asignaturas_taller_by_areas/"+id_area)
        return axios.get(APIURL + "/asignaturas_taller_by_areas/"+id_area)
    }
}
export default new AsignaturasService();
