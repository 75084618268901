/** @format */

import { Component } from "react"
import { withRouter } from "react-router";
import { NavLink } from "react-router-dom"
import AlumnosService from "services/AlumnosService"
import DocentesService from "services/DocentesService"
import AdministrativoService from "services/AdministrativoService"
import AgenteVentaService from "services/AgenteVentaService"
import admon from "../images/gestion_de_usuarios/gerente.svg"
import docente from "../images/gestion_de_usuarios/profesor.svg"
import alumno from "../images/gestion_de_usuarios/graduado.svg"
import agente_ventas from "../images/gestion_de_usuarios/corredor.svg"
import prospecto from "../images/gestion_de_usuarios/curriculum.svg"
import ProspectosAdminService from "services/ProspectosAdminService"
import TutoresAdminService from "services/TutoresAdminService"

class ListaUsuarios extends Component {
	constructor(props) {
		super(props);
		this.state = {
			usuarios: [],
			rol: "",
			totales: [
				{ totalAlumno: '' }
			],
			totalAlumnos: "",
			totalDocentes: "",
			total_Administrativos: "",
			totalAgenteVentas: "",
			totalProspectos: "",
			totalTutores: "",
		}

		this.goBack = this.goBack.bind(this);
	}

	goBack() {
        this.props.history.goBack();
    }

	componentDidMount() {
		AlumnosService.Total_Alumnos().then(res => {
			this.setState({ totalAlumnos: res.data })
		})
		DocentesService.TotalDocentes().then(res => {
			this.setState({ totalDocentes: res.data })
		})
		AdministrativoService.Total_Administrativos().then(res => {
			console.log();
			this.setState({ total_Administrativos: res.data })
		})
		AgenteVentaService.Total_AgenteVentas().then(res => {
			this.setState({ totalAgenteVentas: res.data })
		})
		ProspectosAdminService.Total_Prospectos().then(res => {
			this.setState({ totalProspectos: res.data })
		})
		TutoresAdminService.Total_Tutores().then(res => {
			this.setState({ totalTutores: res.data })
		})
	}

	render() {

		return (
			<>
				<div className='bg-primary_2 py-10'>
					<div className="flex justify-end mx-10 mt-4">
						<nav class="flex" aria-label="Breadcrumb">
							<ol class="inline-flex items-center space-x-1 md:space-x-3">
								<li class="inline-flex items-center">
									<a href="/" class="inline-flex items-center text-sm text-white hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
										<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
										Home
									</a>
								</li>
								<li aria-current="page">
									<div class="flex items-center">
										<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
										<a onClick={this.goBack} class="ml-1 text-sm font-medium text-gray-200 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">usuarios</a>
									</div>
								</li>
								
							</ol>
						</nav>
					</div>
					<h1 className='text-2xl md:text-3xl uppercase text-white font-bold mb-1 text-center font-barlow tracking-widest'>
						Gestión de usuarios
					</h1>
					
						
					<div className='flex flex-row-reverse mb-4 mt-4 mr-20'>
							{(sessionStorage.getItem("roles")==='["SUPER_ADMIN"]'|| sessionStorage.getItem("roles")==='["ADMINISTRATIVO"]'||
							   sessionStorage.getItem("roles")==='["ADMINISTRADOR"]'||sessionStorage.getItem("roles")==='["CONTROL ESCOLAR"]'||
							   sessionStorage.getItem("roles")==='["DIRECTOR"]')&&
								<NavLink to='/app/FormUsuario'
									className='inline-flex sm:ml-3 sm:mt-0 items-start justify-start px-6 py-3 bg-color1 hover:bg-blue focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110'>
									<p className='text-sm font-medium leading-none text-white'>
										<i class='fas fa-plus-circle'></i> Agregar Usuario
									</p>
								</NavLink>
							}
					</div>
					

					<div class=' px-2 md:px-10 pt-4 md:pt-3  overflow-y-auto'>
						<div class='grid grid-cols-3 gap-10'>
							{
								(sessionStorage.getItem("roles")==='["SUPER_ADMIN"]'|| sessionStorage.getItem("roles")==='["ADMINISTRATIVO"]'||
								sessionStorage.getItem("roles")==='["ADMINISTRADOR"]'||sessionStorage.getItem("roles")==='["CONTROL ESCOLAR"]')&&
								<div className=' shadow-2xl rounded-md bg-secondary_2 relative py-5'>
									<div className="absolute w-full top-3 h-10 flex justify-end items-center">
										<div className="w-5 h-5 bg-activo rounded-full mr-7 border"></div>
										</div>
										<div class='flex justify-center'>
										<img
										src={alumno}
										class='w-10 h-10 sm:w-16 sm:h-16'
										alt=''></img>
										</div>
										<div class='flex justify-center py-2'>
										<h1 class='text-2xl sm:text-4xl text-secondary font-black counter'>
											{this.state.totalAlumnos}
										</h1>
									</div>
									<div class='flex justify-center'>
										<h1 class='text-white text-xl sm:text-xl font-barlow tracking-widest'>ALUMNOS</h1>
									</div>
									{/* <div class='flex justify-center'>
									<h1 class='text-activo text-xl sm:text-xl'>ACTIVOS</h1>
									</div> */}
									<div class='flex justify-center pt-3'>
										<NavLink
											to='/app/ListaAlumnos'
											className='inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-6 py-3 bg-color1 hover:bg-secondary focus:outline-none rounded transition transform duration-300 hover:translate-y-1 '>
											<p className='text-sm font-medium leading-none text-white text-center font-barlow tracking-widest'>
											Lista de alumnos
											</p>
										</NavLink>
									</div>
								</div>
								
							}
						{
							(sessionStorage.getItem("roles")==='["SUPER_ADMIN"]'|| sessionStorage.getItem("roles")==='["ADMINISTRATIVO"]'||
							sessionStorage.getItem("roles")==='["ADMINISTRADOR"]'||sessionStorage.getItem("roles")==='["CONTROL ESCOLAR"]'||
							sessionStorage.getItem("roles")==='["DIRECTOR"]')&&
							<div className=' shadow-2xl rounded-md bg-secondary_2 relative py-5'>
								<div className="absolute w-full top-3 h-10 flex justify-end items-center">
									<div className="w-5 h-5 bg-activo rounded-full mr-7 border"></div>
								</div>
								<div class='flex justify-center'>
									<img
										src={docente}
										class='w-8 h-8 lg:w-16 lg:h-16'
										alt=''></img>
								</div>
								<div class='flex justify-center py-2'>
									<h1 class='text-2xl sm:text-4xl text-secondary font-black counter'>
										{this.state.totalDocentes}
									</h1>
								</div>
								<div class='flex justify-center'>
									<h1 class='text-gray-500 text-xl sm:text-xl font-barlow tracking-widest text-white'>DOCENTES</h1>
									<br />
								</div>
								{/* <div class='flex justify-center'>
									<h1 class='text-green-500 text-xl sm:text-xl'>ACTIVOS</h1>
								</div> */}
								<div className=' flex justify-center  pt-3'>
									<NavLink
										to='/app/ListaDocentes'
										className='inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-6 py-3 bg-color1 hover:bg-secondary focus:outline-none rounded transition transform duration-300 hover:translate-y-1 '>
										<p className='text-sm font-medium leading-none text-white text-center font-barlow tracking-widest'>
											Lista de docentes
										</p>
									</NavLink>
								</div>
					</div>
						}	
					

							
								{
									(sessionStorage.getItem("roles")==='["SUPER_ADMIN"]'|| sessionStorage.getItem("roles")==='["ADMINISTRATIVO"]'||
									sessionStorage.getItem("roles")==='["ADMINISTRADOR"]'||sessionStorage.getItem("roles")==='["CONTROL ESCOLAR"]'||
									sessionStorage.getItem("roles")==='["DIRECTOR"]')&&
									<div className=' shadow-2xl rounded-md bg-secondary_2 relative py-5'>
								<div className="absolute w-full top-3 h-10 flex justify-end items-center">
									<div className="w-5 h-5 bg-activo rounded-full mr-7 border"></div>
								</div>
								<div class='flex justify-center'>
									<img
										src={admon}
										class='w-8 h-8 lg:w-16 lg:h-16'
										alt=''></img>
								</div>
								<div class='flex justify-center py-2'>
									<h1 class='text-2xl sm:text-4xl text-secondary font-black counter'>
										{this.state.total_Administrativos}
									</h1>
								</div>

								<div class='flex justify-center'>
									<h1 class='text-gray-500 text-xl sm:text-xl font-barlow text-white tracking-widest'>
										ADMINISTRATIVOS
									</h1>
								</div>
								{/* <div class='flex justify-center'>
									<h1 class='text-green-500 text-xl sm:text-xl'>ACTIVOS</h1>
								</div> */}
								
								<div className='flex justify-center pt-3'>
									<NavLink
										to='/app/ListaAdmon'
										className='inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-6 py-3 bg-color1 hover:bg-secondary focus:outline-none rounded transition transform duration-300 hover:translate-y-1 '>
										<p className='text-sm font-medium leading-none text-white text-center'>
											Lista administrativos
										</p>
									</NavLink>
								</div>
							</div>
							}
							
							{
								(sessionStorage.getItem("roles")==='["SUPER_ADMIN"]'||sessionStorage.getItem("roles")==='["LIDER_VENTAS"]'
								||sessionStorage.getItem("roles")==='["ADMINISTRADOR"]')&&
								<div className=' shadow-2xl rounded-md bg-secondary_2 relative py-5'>
								<div className="absolute w-full top-3 h-10 flex justify-end items-center">
									<div className="w-5 h-5 bg-activo rounded-full mr-7 border"></div>
								</div>
								<div class='flex justify-center'>
									<img
										src={agente_ventas}
										class='w-8 h-8 lg:w-16 lg:h-16'
										alt=''></img>
								</div>
								<div class='flex justify-center'>
									<h1 class='text-2xl sm:text-4xl text-secondary font-black counter'>
										{this.state.totalAgenteVentas}
									</h1>
								</div>
								<div class='flex justify-center py-2'>
									<h1 class='text-gray-500 text-xl sm:text-xl font-barlow text-white tracking-widest uppercase'>
										Agente de ventas
									</h1>
								</div>
								{/* <div class='flex justify-center'>
									<h1 class='text-green-500 text-xl sm:text-xl'>ACTIVOS</h1>
								</div> */}
								<div className='flex justify-center pt-3'>
									<NavLink
										to='/app/ListaAgenteVentas'
										className='inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-6 py-3 bg-color1 hover:bg-secondary focus:outline-none rounded transition transform duration-300 hover:translate-y-1 '>
										<p className='text-sm font-medium leading-none text-white text-center font-barlow tracking-widest'>
											Lista de agente de ventas
										</p>
									</NavLink>
								</div>
									</div>
							}
							{
								(sessionStorage.getItem("roles")==='["SUPER_ADMIN"]'||sessionStorage.getItem("roles")==='["LIDER_VENTAS"]'||
								 sessionStorage.getItem("roles")==='["ADMINISTRADOR"]')&&
								<div className='shadow-2xl rounded-md bg-secondary_2 relative py-5'>
									<div className="absolute w-full top-3 h-10 flex justify-end items-center">
										<div className="w-5 h-5 bg-activo rounded-full mr-7 border"></div>
									</div>
								<div class='flex justify-center'>
									<img
										src={prospecto}
										class='w-8 h-8 lg:w-16 lg:h-16'
										alt=''></img>
								</div>
								<div class='flex justify-center py-2'>
									<h1 class='text-2xl sm:text-4xl text-secondary font-black counter'>
										{this.state.totalProspectos}
									</h1>
								</div>
								<div class='flex justify-center'>
									<h1 class='text-gray-500 text-xl sm:text-xl font-barlow text-white tracking-widest uppercase'>
										Prospectos
									</h1>
								</div>
								{/* <div class='flex justify-center'>
									<h1 class='text-green-500 text-xl sm:text-xl'>ACTIVOS</h1>
								</div> */}
								<div className='flex justify-center pt-3'>
									<NavLink
										to='/app/ListaProspectosAdmin'
										className='inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-6 py-3 bg-color1 hover:bg-secondary focus:outline-none rounded transition transform duration-300 hover:translate-y-1 '>
										<p className='text-sm font-medium leading-none text-white text-center font-barlow tracking-widest'>
											Lista de prospectos
										</p>
									</NavLink>
								</div>
							</div>
							}
							{
								(sessionStorage.getItem("roles")==='["SUPER_ADMIN"]'|| sessionStorage.getItem("roles")==='["ADMINISTRADOR"]'||
								 sessionStorage.getItem("roles")==='["DIRECTOR"]'||sessionStorage.getItem("roles")==='["CONTROL ESCOLAR"]'||
								 sessionStorage.getItem("roles")==='["ADMINISTRATIVO"]')&&
								<div className='shadow-2xl rounded-md bg-secondary_2 relative py-5'>
										<div className="absolute w-full top-3 h-10 flex justify-end items-center">
											<div className="w-5 h-5 bg-activo rounded-full mr-7 border"></div>
										</div>
									<div class='flex justify-center'>
										<img
											src={prospecto}
											class='w-8 h-8 lg:w-16 lg:h-16'
											alt=''></img>
									</div>
				
									<div class='flex justify-center py-2'>
										<h1 class='text-2xl sm:text-4xl text-secondary font-black counter'>
											{this.state.totalTutores}
										</h1>
									</div>
								
								
								<div class='flex justify-center'>
									<h1 class='text-gray-500 text-xl sm:text-xl font-barlow text-white tracking-widest uppercase'>
										Tutores
									</h1>
								</div>
								<div className='flex justify-center pt-3'>
									<NavLink
										to='/app/ListaTutores'
										className='inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-6 py-3 bg-color1 hover:bg-secondary focus:outline-none rounded transition transform duration-300 hover:translate-y-1 '>
										<p className='text-sm font-medium leading-none text-white text-center font-barlow tracking-widest'>
											Lista de tutores
										</p>
									</NavLink>
								</div>
							</div>
							}
								
							
								
							

						</div>
					</div>
				</div>

			</>
		)
	}
}

export default withRouter(ListaUsuarios);