import axios from "axios"
import { APIURL } from "./Host"

class TutoresAdminService {
	Total_Tutores() {
		return axios.get(APIURL + "/total_tutores")
	}

	ListaTurores(datos){
		return axios.post(APIURL+"/lista_tutores_admin",datos);
	}
	






}
export default new TutoresAdminService()