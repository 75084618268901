import { Component } from "react";
import { withRouter } from "react-router";
import AlumnosService from "services/AlumnosService";
import swal from 'sweetalert2/dist/sweetalert2.js';


class AcademicoAlumnos extends Component {

    constructor(props) {
        super(props);
        this.state = {
            curso_actual: [],
            especialidades: [],
            alumno: [],
            periodos: [],
            id_alumno: '',
            mostrarEnCurso: '',

        }


        this.goBack = this.goBack.bind(this);
        this.change_especilidad = this.change_especilidad.bind(this);


    }

    goBack() {
        this.props.history.goBack();
    }



    componentDidMount() {
        let usuario_id = sessionStorage.getItem('id');
        AlumnosService.DatosAlumnoEsp(usuario_id).then((res) => {
            //console.log("datos", res.data[0]);
            this.setState({ especialidades: res.data });
        })
    }

    change_especilidad = (event) => {
        let user_id = sessionStorage.getItem('id');
        let seleccionado = document.getElementById("especialidad").value;
        AlumnosService.InfoEscolarAlumno(user_id, seleccionado).then((res) => {
            this.setState({
                alumno: res.data,
                id_alumno: res.data.id_alumno
            });
            AlumnosService.EnCurso(this.state.id_alumno, seleccionado).then((res) => {
                //console.log("ENCURSO",res.data);
                this.setState({
                    periodos: res.data.periodo
                });
            });
        });

    }

    render() {
        return (
            <>
                <div>

                    <div className="flex justify-end mx-10 mt-4">

                        <nav class="flex" aria-label="Breadcrumb">
                            <ol class="inline-flex items-center space-x-1 md:space-x-3">
                                <li class="inline-flex items-center">
                                    <a href="/" class="inline-flex items-center text-sm text-white hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
                                        <svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                                        Home
                                    </a>
                                </li>
                                <li aria-current="page">
                                    <div class="flex items-center">
                                        <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                        <span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">académico</span>
                                    </div>
                                </li>
                            </ol>
                        </nav>
                    </div>

                    <div class="grid grid-cols-4 xl:grid-cols-4 2xl:grid-cols-4 md:grid-cols-2 sm:grid-cols-1">

                        <div>
                            <strong className="text-white">Especialidad:</strong><br />
                            <select class="flex items-end py-1 px-2 rounded-lg border-2 border-color1 mt-1 focus:outline-none focus:ring-2 focus:ring-secondary focus:border-transparent"
                                name="especialidad"
                                id="especialidad"
                                onChange={this.change_especilidad}
                                required>
                                <option value="">seleccione una opción</option>
                                {
                                    this.state.especialidades.map(
                                        especialidad =>
                                            <option value={especialidad.ID_Especialidad}>{especialidad.Especialidad}</option>
                                    )
                                }
                            </select>
                        </div>

                    </div>
                    <div hidden={this.state.mostrarEnCurso} >
                        {
                            this.state.periodos.map(
                                periodo =>
                                    <div className="mx-8 my-8 mr-8 overflow-x-auto ">
                                        <p align="center" className="font-bold text-white">Periodo: {periodo.fecha_inicio + "  al  " + periodo.fecha_fin}</p>

                                        <div className="px-2 md:px-10 pt-4 md:pt-7 pb-5 overflow-y-auto" >
                                            <table className="w-full whitespace-nowrap my_table">
                                                <thead className="h-10">
                                                    <tr>
                                                        <th className="border border-slate-300">Clave_Asig</th>
                                                        <th className="border border-slate-300">Créditos</th>
                                                        <th className="border border-slate-300">Asignatura</th>
                                                        <th className="border border-slate-300">Parcial 1</th>
                                                        <th className="border border-slate-300">Parcial 2</th>
                                                        <th className="border border-slate-300">Ordinario</th>
                                                        <th className="border border-slate-300">Título</th>
                                                        
                                                    </tr>
                                                </thead>
                                                {periodo.materias.map(
                                                    materia =>
                                                        <tbody className="h-20">
                                                            <tr className="h-20 text-sm leading-none text-gray-800 group border border-slate-300">
                                                                <td >
                                                                    <p className="font-medium">{materia.clave_asignatura}</p>
                                                                </td>
                                                                <td >
                                                                    <p className="font-medium">{materia.creditos_asignatura}</p>
                                                                </td>
                                                                <td >
                                                                    <p className="font-medium">{materia.nombre_asignatura}</p>
                                                                </td>
                                                                {
                                                                    materia.calificaciones.map(
                                                                        parcial =>
                                                                            <td>
                                                                                <p>{parcial.calificacion}</p>
                                                                            </td>
                                                                    )
                                                                }

                                                              

                                                            </tr>
                                                        </tbody>
                                                )
                                                }

                                            </table><br /><br />
                                        </div><br />
                                    </div>
                            )
                        }
                    </div>






                </div>


            </>
        );
    }

}

export default withRouter(AcademicoAlumnos);