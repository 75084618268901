import { Component } from "react";
import { withRouter } from "react-router";
import GestorPagoService from "services/GestorPagoService";
import swal from 'sweetalert2';
import verde from "../images/luzverde.png";
import amarillo from "../images/luzama.png";
import rojo from "../images/luzroja.png";
import { NavLink, Link } from "react-router-dom";
import PagosService from "services/PagosService";

class GestionMensualidades extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id_periodo: this.props.newParams ? this.props.newParams.id_periodo : "",
            id_au: this.props.newParams ? this.props.newParams.id_au : "",
            nombre: this.props.newParams ? this.props.newParams.nombre : "",
            pagos: [],
            pago: [],
            modalDescuento: false,
            descuento_porcentaje: "",
            descuento_cantidad: "",
            total_mensual: "",
            id_pago: "",
            checked: false,
            checked2: false,
            descripcion_porcentaje: "",
            concepto_porcentaje: "",
            descripcion_cantidad: "",
            concepto_cantidad: "",
            porcentaje: "",
            cantidad: "",
            comprobante_pago:'',
            fecha:'',
            mdalForm: false,
            modalPago:false,
            kardex_id: "",
        }

        this.handleChange = this.handleChange.bind(this);
        this.goBack = this.goBack.bind(this);
        this.ModalDescuento = this.ModalDescuento.bind(this);
        this.GenerarDescuentoPorcentaje = this.GenerarDescuentoPorcentaje.bind(this);
        this.GenerarDescuentoCantidad = this.GenerarDescuentoCantidad.bind(this);
        this.inputChange = this.inputChange.bind(this);
        this.CalculaPorcentaje = this.CalculaPorcentaje.bind(this);
        this.renderverde = this.renderverde.bind(this);
        this.renderamarillo = this.renderamarillo.bind(this);
        this.renderRojo = this.renderRojo.bind(this);
        this.Pagar = this.Pagar.bind(this);
        this.MostrarPago=this.MostrarPago.bind(this);

    }


    goBack() {
        this.props.history.goBack();
    }

    componentDidMount() {
        console.log("id_periodo == "+this.state.id_periodo  +"  id_au === "+this.state.id_au)
        if (!this.state.id_periodo || !this.state.id_au) {
            swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Ha ocurrido un error',
            });
            this.goBack();
        }
        let id_periodo = this.state.id_periodo;
        let id_au = this.state.id_au;
        GestorPagoService.GestionMensualidades(id_periodo, id_au).then((res) => {
            console.log("pagos   ====>",res.data)
            this.setState({ pagos: res.data });

        })

    }

    Modalpagar(id_pago, tipo_pago, kardex_id) {
        this.setState({ mdalForm: true, id_pago: id_pago, tipo_pago: tipo_pago, kardex_id: kardex_id });
    }

    Pagar = (event) => {
        event.preventDefault();
        var preview = document.querySelector('img');
        this.state.file = document.getElementById('archivo').files[0];
        var reader = new FileReader();
        reader.onload = this.handleFile;
        if (this.state.file) {
            reader.readAsDataURL(this.state.file);
        } else {
            preview.src = "";
            swal.fire({
                imageUrl: "https://multimedia-uea-metaverso.netlify.app/assets/uea19.6bc028c7.webp",
                imageHeight: '150',
                title: 'Error',
                text: 'Falta agregar el comprobante de pago',
            });
        }
    }

    MostrarPago(id_p,tipo_P,k_id){
		
			PagosService.PagoByID_Pago(id_p).then((res)=>{
				console.log("pago by id ", res.data);
				let pago=res.data;
				this.setState({
					cantidad:pago.cantidad,
					fecha:pago.fecha,
					comprobante_pago:pago.comprobante_pago,
					modalPago:true
				});


			})
		
		
	}

    handleFile = (e) => {

        const content = e.target.result;
        var filePreview = document.createElement('img');
        filePreview.id = 'file-preview';

        const parts = this.state.file.name.split('.')
        const extension = parts[parts.length - 1]
        var base64image = e.target.result;
        base64image = base64image.split("base64,")[1];

        var com = null;
        if (extension == 'pdf') {
            com = "data:application/pdf;base64," + base64image;
        }
        else {
            com = "data:image/jpg;base64," + base64image;
        }
        console.log("imagen base64 *** *" + base64image);
        let JSONPago = {
            id_pago: this.state.id_pago,
            cantidad: this.state.cantidad,
            folio_ticked: this.state.folio_ticked,
            tipo_pago: this.state.tipo_pago,
            usuario_alumno_id: this.state.id_au,
            periodo_id: this.state.id_periodo,
            kardex_id: this.state.kardex_id,
            comprobanteBase64: com,
        }
        console.log("datos a enviar ", JSONPago);
        PagosService.EnviarPago(JSONPago).then((res) => {
            if (res.data > 0) {
                swal.fire({
                    imageUrl: "https://multimedia-uea-metaverso.netlify.app/assets/uea19.6bc028c7.webp",
                    imageHeight: '150',
                    text: 'Registro agregado correctamente',
                });
                this.setState({ mdalForm: false });
                this.componentDidMount();
            }
            else {
                swal.fire({
                    imageUrl: "https://multimedia-uea-metaverso.netlify.app/assets/uea19.6bc028c7.webp",
                    imageHeight: '150',
                    title: 'Error',
                    text: 'Error al agregar el registro',
                })
            }
        })
    }

    ModalDescuento(id_pago) {
        GestorPagoService.DetallesDescuento(id_pago).then((res) => {

            if (res.data.Descuentos >= res.data.Costo_Pago) {
                swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Ya no es aplicable un descuento',
                });
                this.ModalDescuentoCerrar();

            }
            this.setState({ modalDescuento: !this.state.modalDescuento, id_pago: id_pago })
            this.setState({ pago: res.data });


        });
    }

    ModalDescuentoCerrar = () => {
        this.setState({ modalDescuento: !this.state.modalDescuento, checked: false, checked2: false, descuento_porcentaje: " ", descuento_cantidad: " " })
    }

    GenerarDescuentoPorcentaje = (event) => {
        event.preventDefault();
        let id_pago = this.state.id_pago;
        let porcentaje = this.state.descuento_porcentaje;
        let cantidad = this.state.descuento_cantidad;
        let total = this.state.total_mensual;
        let user_id = sessionStorage.getItem('id');

        let descripcion = "";
        if (this.state.descripcion_cantidad) {
            descripcion = this.state.descripcion_cantidad;
        }
        if (this.state.descripcion_porcentaje) {
            descripcion = this.state.descripcion_porcentaje;
        }
        let concepto = "";
        if (this.state.concepto_cantidad) {
            concepto = this.state.concepto_cantidad;
        }
        if (this.state.concepto_porcentaje) {
            concepto = this.state.concepto_porcentaje;
        }
        let descuento_ob = {
            id_pago: id_pago,
            porcentaje: porcentaje,
            cantidad: porcentaje,
            total: total,
            descripcion: descripcion,
            concepto: concepto,
            iduser: user_id,
        };

        if (this.state.total_mensual == 0) {
            swal.fire({
                title: 'Alerta',
                text: "El descuento cubre la totalidad de la mensualidad",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'aceptar'
            }).then((result) => {
                if (result.isConfirmed) {
                    // console.log("esto se va pagar", descuento_ob);
                    GestorPagoService.GeneraDescuento(descuento_ob).then((res) => {
                        if (res.data == 0) {
                            swal.fire({
                                icon: 'error',
                                title: 'Error',
                                text: 'la cantidad excede el monto total',
                            });
                            //this.ModalDescuentoCerrar();

                        } else {
                            swal.fire({
                                icon: 'success',
                                title: 'éxito',
                                text: 'descuento generado con éxito',
                            });
                            this.ModalDescuentoCerrar();
                            this.componentDidMount();
                        }


                    });
                }
            })
        } else {
            GestorPagoService.GeneraDescuento(descuento_ob).then((res) => {
                if (res.data == 0) {
                    swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'la cantidad excede el monto total',
                    });
                    //this.ModalDescuentoCerrar();

                } else {
                    swal.fire({
                        icon: 'success',
                        title: 'éxito',
                        text: 'descuento generado con éxito',
                    });
                    this.ModalDescuentoCerrar();
                    this.componentDidMount();
                }

            });
        }


    }

    GenerarDescuentoCantidad = (event) => {
        event.preventDefault();
        let id_pago = this.state.id_pago;
        let porcentaje = this.state.descuento_porcentaje;
        let cantidad = this.state.descuento_cantidad;
        let total = this.state.total_mensual;
        let user_id = sessionStorage.getItem('id');

        let descripcion = "";
        if (this.state.descripcion_cantidad) {
            descripcion = this.state.descripcion_cantidad;
        }
        if (this.state.descripcion_porcentaje) {
            descripcion = this.state.descripcion_porcentaje;
        }
        let concepto = "";
        if (this.state.concepto_cantidad) {
            concepto = this.state.concepto_cantidad;
        }
        if (this.state.concepto_porcentaje) {
            concepto = this.state.concepto_porcentaje;
        }


        let descuento_ob = {
            id_pago: id_pago,
            porcentaje: porcentaje,
            cantidad: cantidad,
            total: total,
            descripcion: descripcion,
            concepto: concepto,
            iduser: user_id,
        };

        if (this.state.total_mensual == 0) {
            swal.fire({
                title: 'Alerta',
                text: "El descuento cubre la totalidad de la mensualidad",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'aceptar'
            }).then((result) => {
                if (result.isConfirmed) {
                    // console.log("esto se va pagar", descuento_ob);
                    GestorPagoService.GeneraDescuento(descuento_ob).then((res) => {
                        if (res.data == 0) {
                            swal.fire({
                                icon: 'error',
                                title: 'Error',
                                text: 'la cantidad excede el monto total',
                            });
                            //this.ModalDescuentoCerrar();

                        } else {
                            swal.fire({
                                icon: 'success',
                                title: 'éxito',
                                text: 'descuento generado con éxito',
                            });
                            this.ModalDescuentoCerrar();
                            this.componentDidMount();
                        }

                    });
                }
            })
        } else {
            GestorPagoService.GeneraDescuento(descuento_ob).then((res) => {
                if (res.data == 0) {
                    swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'la cantidad excede el monto total',
                    });
                    //this.ModalDescuentoCerrar();

                } else {
                    swal.fire({
                        icon: 'success',
                        title: 'éxito',
                        text: 'descuento generado con éxito',
                    });
                    this.ModalDescuentoCerrar();
                    this.componentDidMount();
                }

            });
        }
    }



    inputChange = (event) => {

        switch (event.target.name) {
            case "porcentaje":
                this.CalculaPorcentaje(event.target.value);
                //this.setState({ porcentaje: event.target.value });
                break;
            case "descripcion_porcentaje":
                this.setState({ descripcion_porcentaje: event.target.value });
                break;
            case "concepto_porcentaje":
                this.setState({ concepto_porcentaje: event.target.value });
                break;
            case "cantidad":
                this.CalculaCantidad(event.target.value);
                //this.setState({ cantidad: event.target.value });
                break;
            case "descripcion_cantidad":
                this.setState({ descripcion_cantidad: event.target.value });
                break;
            case "concepto_cantidad":
                this.setState({ concepto_cantidad: event.target.value });
                break;
            default:
        }

    };



    handleChange = (event) => {
        let conta = event.target.value;
        if (conta == 1) {
            this.setState({
                checked: true, checked2: false, descuento_porcentaje: " ", total_mensual: " ", descuento_cantidad: " ",
                descripcion_cantidad: "", descripcion_porcentaje: "", concepto_porcentaje: "", concepto_cantidad: "", porcentaje: "", cantidad: ""
            });
        }
        if (conta == 2) {
            this.setState({
                checked: false, checked2: true, descuento_porcentaje: " ", total_mensual: " ", descuento_cantidad: " ",
                descripcion_cantidad: "", descripcion_porcentaje: "", concepto_porcentaje: "", concepto_cantidad: "", porcentaje: "", cantidad: ""
            });
        }
    }

    CalculaPorcentaje(valor) {

        if (valor > 100) {
            swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'El porcentaje no debe ser mayor a 100',
            });
            this.ModalDescuentoCerrar();

        } else {

            let newText = '';
            let numbers = '0123456789';
            let vacio = 0;

            for (var i = 0; i < valor.length; i++) {
                if (numbers.indexOf(valor[i]) > -1) {
                    newText = newText + valor[i];
                }
                else {
                    this.setState({ porcentaje: vacio });
                }
            }

            this.setState({ porcentaje: newText });
            var percent = newText;

            var num = this.state.pago.Costo_Pago;
            var num2 = (num / 100) * percent;
            var num3 = num - num2;

            this.setState({ descuento_porcentaje: num2, total_mensual: num3 - this.state.pago.Descuentos }, () => {
                if (this.state.total_mensual < 0) {

                    swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'La cantidad del descuento excede el monto total',
                    });
                    this.ModalDescuentoCerrar();

                }
            })
        }
    }

    CalculaCantidad(valor) {



        if (valor > this.state.pago.Costo_Pago) {
            swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'La cantidad no debe ser mayor al costo total',
            });
            this.ModalDescuentoCerrar();

        } else {
            let newText = '';
            let numbers = '0123456789';
            var vacio = 0;

            for (var i = 0; i < valor.length; i++) {
                if (numbers.indexOf(valor[i]) > -1) {
                    newText = newText + valor[i];
                }
                else {
                    this.setState({ cantidad: vacio });
                }
            }

            this.setState({ cantidad: newText });
            var val = newText;
            var num = this.state.pago.Costo_Pago;

            var num3 = num - val;

            this.setState({ descuento_cantidad: val, total_mensual: num3 - this.state.pago.Descuentos }, () => {
                if (this.state.total_mensual < 0) {

                    swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'La cantidad del descuento excede el monto total',
                    });
                    this.ModalDescuentoCerrar();

                }
            })


        }
    }

    CalucularMes(mes) {
        switch (mes) {
            case 1:
                {
                    mes = "enero";
                    break;
                }
            case 2:
                {
                    mes = "febrero";
                    break;
                }
            case 3:
                {
                    mes = "marzo";
                    break;
                }
            case 4:
                {
                    mes = "abril";
                    break;
                }
            case 5:
                {
                    mes = "mayo";
                    break;
                }
            case 6:
                {
                    mes = "junio";
                    break;
                }
            case 7:
                {
                    mes = "julio";
                    break;
                }
            case 8:
                {
                    mes = "agosto";
                    break;
                }
            case 9:
                {
                    mes = "septiembre";
                    break;
                }
            case 10:
                {
                    mes = "octubre";
                    break;
                }
            case 11:
                {
                    mes = "noviembre";
                    break;
                }
            case 12:
                {
                    mes = "diciembre";
                    break;
                }
            default:
                {
                    mes = "Error";
                    break;
                }
        }
        return mes;
    }


    renderverde(suma, tipo) {
        console.log("suma ================= "+suma +"        tipo "+tipo)
        return (
            <div class='grid justify-items-center' id="verde">
                <img
                    src={verde}
                    class='md:w-16 md:h-16'
                    alt=''></img>
                <span class="bg-green-100 text-green-800 text-sm font-medium inline-flex items-center px-2.5 py-0.5 rounded mr-2 dark:bg-gray-700 dark:text-gray-300">
                    <svg class="mr-1 w-3 h-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clip-rule="evenodd"></path></svg>
                    {suma == tipo ? (<span class="text-gray-800 text-xs font-semibold rounded dark:text-gray-300">pagar</span>) : suma > tipo ? (<span class="text-gray-800 text-xs font-semibold rounded dark:text-gray-300">pagado</span>) : <span class="text-gray-800 text-xs font-semibold rounded dark:text-gray-300">próximo</span>}
                </span>
            </div>
        );
    }

    renderamarillo(suma, tipo) {
        return (
            <div class='grid justify-center' id="verde">
                <img
                    src={amarillo}
                    class='md:w-16 md:h-16'
                    alt=''></img>
                <span class="bg-yellow-400 text-yellow-800 text-sm font-medium inline-flex items-center px-2.5 py-0.5 rounded mr-2 dark:bg-gray-700 dark:text-gray-300">
                    <svg class="mr-1 w-3 h-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clip-rule="evenodd"></path></svg>
                    {suma == tipo ? (<span class="text-gray-800 text-xs font-semibold rounded dark:text-gray-300">pagar</span>) : suma > tipo ? (<span class="text-gray-800 text-xs font-semibold rounded dark:text-gray-300">pagado</span>) : <span class="text-gray-800 text-xs font-semibold rounded dark:text-gray-300">próximo</span>}
                </span>
            </div>

        );
    }

    renderRojo(suma, tipo) {
        return (
            <div class='grid justify-items-center' id="verde">
                <img
                    src={rojo}
                    class='md:w-16 md:h-16'
                    alt=''></img>
                <span class="bg-red-100 text-red-800 text-sm font-medium inline-flex items-center px-2.5 py-0.5 rounded mr-2 dark:bg-gray-700 dark:text-gray-300">
                    <svg class="mr-1 w-3 h-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clip-rule="evenodd"></path></svg>
                    {suma == tipo ? (<span class="text-gray-800 text-xs font-semibold rounded dark:text-gray-300">pagar</span>) : suma > tipo ? (<span class="text-gray-800 text-xs font-semibold rounded dark:text-gray-300">pagado</span>) : <span class="text-gray-800 text-xs font-semibold rounded dark:text-gray-300">próximo</span>}
                </span>
            </div>
        );
    }


    render() {
        return (
            <>
                <div>

                    <div className="flex justify-end mx-10 mt-4">

                        <nav class="flex" aria-label="Breadcrumb">
                            <ol class="inline-flex items-center space-x-1 md:space-x-3">
                                <li class="inline-flex items-center">
                                    <a href="/" class="inline-flex items-center text-sm text-white hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
                                        <svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                                        Home
                                    </a>
                                </li>
                                <li aria-current="page">
                                    <div class="flex items-center">
                                        <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                        <a onClick={this.goBack} class="ml-1 text-sm font-medium text-gray-200 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">pagos</a>
                                    </div>
                                </li>
                                <li aria-current="page">
                                    <div class="flex items-center">
                                        <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                        <a onClick={this.goBack} class="ml-1 text-sm font-medium text-gray-200 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">actual</a>
                                    </div>
                                </li>
                                <li aria-current="page">
                                    <div class="flex items-center">
                                        <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                        <span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">mes</span>
                                    </div>
                                </li>
                            </ol>
                        </nav>
                    </div>

                    <div className="relative p-4 sm:p-6 rounded-sm overflow-hidden mb-2">
                        <div className="relative text-center">
                            <h1 className="text-xl uppercase md:text-xl text-white font-bold mb-1 text-center">
                                mensualidades del alumno {this.state.nombre}
                            </h1>
                        </div>
                    </div>

                    <div class="w-full my-8">
                        <div className="mx-10 my-4 overflow-x-auto flex">

                            <table className="w-full whitespace-nowrap my_table">
                                <thead className="h-10">
                                    <tr>
                                        <th className="border border-slate-300">Mensualidad</th>
                                        <th className="border border-slate-300">Fecha de pago</th>
                                        <th className="border border-slate-300">Cantidad pagada</th>
                                        <th className="border border-slate-300">Estado</th>
                                        <th className="border border-slate-300">Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.pagos.map(
                                            pago =>

                                                <tr className="h-20 text-sm leading-none text-gray-800 group border border-slate-300">


                                                    <td className="pl-2 text-left">

                                                        <p className="font-medium">{pago.Tipo_Pago}</p>

                                                    </td>
                                                    <td className="pl-2 text-left">
                                                        {!pago.Fecha ? (
                                                            <span class="bg-gray-100 text-primary_2 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300"><i class="fas fa-exclamation-triangle"></i> no hay fecha</span>
                                                        )
                                                            :
                                                            <p className="font-medium">{(pago.Fecha.split("-")[2]).split("T")[0] + "-" + this.CalucularMes(parseInt(pago.Fecha.split("-")[1])) + "-" + pago.Fecha.split("-")[0]}</p>
                                                        }
                                                    </td>

                                                    <td className="pl-2 text-left">
                                                        {pago.Cantidad == 0 ? (
                                                            <span class="bg-gray-100 text-primary_2 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300"><i class="fas fa-exclamation-triangle"></i> no hay cantidad</span>
                                                        )
                                                            :
                                                            <p className="font-medium">{pago.Cantidad}</p>
                                                        }
                                                    </td>


                                                    <td>
                                                        {pago.Semaforo == "VERDE" ? (
                                                            this.renderverde(pago.Suma_Pagos, pago.Tipo_Pago)
                                                        )
                                                            :
                                                            pago.Semaforo == "AMARILLO" ?
                                                                this.renderamarillo(pago.Suma_Pagos, pago.Tipo_Pago)
                                                                :
                                                                pago.Semaforo == "ROJO" ?
                                                                    this.renderRojo(pago.Suma_Pagos, pago.Tipo_Pago)
                                                                    :
                                                                    <p className="font-medium">{this.renderverde()}</p>

                                                        }

                                                    </td>


                                                    <td className="pl-2 text-left">
                                                        {
                                                            pago.Suma_Pagos == pago.Tipo_Pago ? (
                                                                <div className="grid gap-2 grid-cols-1">
                                                                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={() => this.Modalpagar(pago.ID_Pago, pago.Tipo_Pago, pago.Kardex_ID)} title="Detalles alumno">
                                                                        pagar
                                                                    </button>
                                                                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={() => this.ModalDescuento(pago.ID_Pago)} title="Descuentos">
                                                                        descuentos
                                                                    </button>

                                                                </div>
                                                            ) :
                                                                pago.Suma_Pagos > pago.Tipo_Pago ? (
                                                                    <div className="grid gap-2 grid-cols-1">
                                                                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={() => this.MostrarPago(pago.ID_Pago, pago.Tipo_Pago, pago.Kardex_ID)} title="Detalles pago">
                                                                            reporte
                                                                        </button>

                                                                    </div>
                                                                ) :
                                                                    <span class="bg-gray-100 text-primary_2 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">
                                                                        <i class="fas fa-exclamation-triangle"></i> próximo a pagar
                                                                    </span>
                                                        }

                                                    </td>

                                                </tr>





                                        )

                                    }


                                </tbody>


                            </table>

                            {this.state.mdalForm ? (
                                <>
                                    <form onSubmit={this.Pagar}>
                                        <div div class="overflow-y-auto h-32">
                                            <div className='justify-center items-center fixed overflow-auto inset-0 z-50 outline-none focus:outline-none animate__animated animate__fadeIn'>
                                                <div className='relative w-auto my-6 mx-auto max-w-3xl'>
                                                    {/*content*/}
                                                    <div className='rounded-3xl relative flex flex-col w-full bg-base outline-none focus:outline-none shadow-sm2 z-20'>
                                                        {/*header*/}
                                                        <div className=' rounded-t-3xl'>
                                                            <p class='text-primary text-3xl text-center my-5 font-bold uppercase'>Detalles del pago</p>
                                                        </div>
                                                        {/*body*/}
                                                        <div className='relative flex-auto'>
                                                            <div class='grid grid-cols-1 gap-5 px-6 py-5 '>
                                                                <label className='md:text-sm text-gray-500 text-light font-bold  text-primary text-sm'>
                                                                    Nombre completo
                                                                </label>
                                                                <p class='mb-2 font-semibold text-secondary '>
                                                                    {this.state.nombre}
                                                                </p>

                                                                <label className='md:text-sm text-gray-500 text-light font-bold text-primary text-sm' >
                                                                    Folio del ticket
                                                                    <span className='text-red-500 text-xl'>*</span>
                                                                </label>
                                                                <input
                                                                    className='mt-1 w-1/2 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out'
                                                                    type='text'
                                                                    name='folio_ticked'
                                                                    onChange={event => this.setState({ folio_ticked: event.target.value })}
                                                                    required
                                                                />

                                                                <label className='md:text-sm text-gray-500 text-light font-bold text-primary text-sm'>
                                                                    Cantidad de pago mensual
                                                                    <span className='text-red-500 text-xl'>*</span>
                                                                </label>
                                                                <input
                                                                    className='mt-1 w-1/2 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out'
                                                                    type='text'
                                                                    name='cantidad'
                                                                    onChange={event => this.setState({ cantidad: event.target.value })}
                                                                    required
                                                                />

                                                                <label className='md:text-sm text-gray-500 text-light font-bold text-primary text-sm' >
                                                                    Agregar documento
                                                                    <span className='text-red-500 text-xl'>*</span>
                                                                </label>
                                                                <input
                                                                    type="file"
                                                                    src="#"
                                                                    name="archivo"
                                                                    id="archivo"
                                                                    accept=" image/png, .jpeg, .jpg, .pdf "
                                                                    required></input>


                                                            </div>
                                                        </div>
                                                        {/*footer*/}
                                                        <div className='flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b'>
                                                            <button
                                                                className='bg-emerald-500 text-black active:bg-gray-600 font-bold uppercase text-sm px-6  rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                                                                type='submit'>
                                                                pagar
                                                            </button>

                                                            <button
                                                                className='text-red-500 background-transparent font-bold uppercase px-6 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                                                                type='button'
                                                                onClick={() => this.setState({ mdalForm: false })}>
                                                                Cerrar
                                                            </button>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div onClick={() => this.setState({ mdalForm: false })} className='opacity-25 fixed inset-0 cursor-pointer bg-black'></div>
                                            </div>
                                        </div>
                                    </form>
                                </>
                            ) : null}

                            {this.state.modalDescuento ? (
                                <>

                                    <div div class="overflow-y-auto h-32">
                                        <div className='justify-center items-center fixed overflow-auto inset-0 z-50 outline-none focus:outline-none animate__animated animate__fadeIn'>
                                            <div className='relative w-auto my-6 mx-auto max-w-3xl'>
                                                {/*content*/}
                                                <div className='rounded-3xl relative flex flex-col w-full bg-base outline-none focus:outline-none shadow-sm2'>
                                                    {/*header*/}
                                                    <div className='rounded-t-3xl'>
                                                        <h3 className='text-primary text-3xl text-center my-5 font-bold uppercase'>
                                                            Descuento

                                                        </h3>
                                                        <div class="mx-10 mb-6 flex">
                                                            <div class="px-3 mb-6 md:mb-0">
                                                                <label class="uppercase tracking-wide text-black text-xs font-bold mb-2" for="company">
                                                                    costo:
                                                                </label>
                                                                <span class="bg-gray-100 text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">{this.state.pago.Costo_Pago}</span>
                                                            </div>
                                                            <div class="px-3">
                                                                <label class="uppercase tracking-wide text-black text-xs font-bold mb-2" for="title">
                                                                    concepto:
                                                                </label>
                                                                <span class="bg-gray-100 text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">{this.state.pago.Concepto_Pago}</span>
                                                            </div>

                                                            <div class="px-3">
                                                                <label class="uppercase tracking-wide text-black text-xs font-bold mb-2" for="title">
                                                                    Descuentos:
                                                                </label>
                                                                <span class="bg-gray-100 text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">{this.state.pago.Descuentos}</span>
                                                            </div>

                                                            <div className="mx-2 flex-auto">
                                                                <label class="uppercase tracking-wide text-black text-xs font-bold mb-2 " for="title">
                                                                    nuevo descuento:
                                                                </label>
                                                                <input onChange={this.handleChange} class="h-4 w-4 mx-2 cursor-pointer" type="radio" name="descuento" value={1} />
                                                                <label for="flexCheckDefault">
                                                                    porcentaje
                                                                </label>

                                                                <input onChange={this.handleChange} class="h-4 w-4 mx-2 cursor-pointer" type="radio" name="descuento" value={2} />
                                                                <label for="flexCheckDefault">
                                                                    cantidad fija
                                                                </label>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/*body*/}
                                                    <div className="mx-8">


                                                        {this.state.checked ? (
                                                            <form onSubmit={this.GenerarDescuentoPorcentaje}>
                                                                <div className="mx-2">
                                                                    <div className='relative flex-auto mx-8'>
                                                                        <div class="mb-6">
                                                                            <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Porcentaje</label>
                                                                            <input type="text" name="porcentaje" value={this.state.porcentaje}
                                                                                onChange={this.inputChange} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="ej: 50" required />
                                                                        </div>
                                                                        <div class="mb-6">
                                                                            <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Descripción</label>
                                                                            <textarea type="text" name="descripcion_porcentaje" value={this.state.descripcion_porcentaje}
                                                                                onChange={this.inputChange} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="descrpción" rows="3" required />
                                                                        </div>
                                                                        <div class="mb-6">
                                                                            <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Concepto</label>
                                                                            <input type="text" name="concepto_porcentaje" value={this.state.concepto_porcentaje}
                                                                                onChange={this.inputChange} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="descuento" required />
                                                                        </div>

                                                                        <div class="grid grid-cols-12 w-full mb-1 text-center">
                                                                            <div className="col-span-4">

                                                                            </div>

                                                                            <div className="border border-gray-700 mx-2 col-span-4 bg-gray-400">
                                                                                <span>Mensualidad:</span>

                                                                            </div>
                                                                            <div className="border border-gray-700 col-span-4 bg-gray-400">
                                                                                <div className=" mx-20 text-right">
                                                                                    <span>$ {this.state.pago.Costo_Pago}</span>
                                                                                </div>

                                                                            </div>

                                                                        </div>
                                                                        <div class="grid grid-cols-12 w-full mb-1 text-center">
                                                                            <div className="col-span-4">

                                                                            </div>

                                                                            <div className="border border-gray-700 mx-2 col-span-4 bg-gray-400">
                                                                                <span>Descuento anterior:</span>

                                                                            </div>
                                                                            <div className="border border-gray-700 col-span-4 bg-gray-400">
                                                                                <div className=" mx-20 text-right">
                                                                                    <span>$ {this.state.pago.Descuentos}</span>
                                                                                </div>

                                                                            </div>

                                                                        </div>
                                                                        <div class="grid grid-cols-12 w-full mb-1 text-center">
                                                                            <div className="col-span-4">

                                                                            </div>

                                                                            <div className="border border-gray-700 mx-2 col-span-4 bg-gray-400">
                                                                                <span>Descuento actual:</span>

                                                                            </div>
                                                                            <div className="border border-gray-700 col-span-4 bg-gray-400">
                                                                                <div className=" mx-20 text-right">
                                                                                    <span>$ {this.state.descuento_porcentaje}</span>
                                                                                </div>

                                                                            </div>

                                                                        </div>

                                                                        <div class="grid grid-cols-12 w-full mb-1 text-center">
                                                                            <div className="col-span-4">

                                                                            </div>

                                                                            <div className="border border-gray-700 mx-2 col-span-4 bg-gray-400">
                                                                                <span>Total:</span>

                                                                            </div>
                                                                            <div className="border border-gray-700 col-span-4 bg-gray-400">
                                                                                <div className=" mx-20 text-right">
                                                                                    <span>$ {this.state.total_mensual}</span>
                                                                                </div>

                                                                            </div>

                                                                        </div>


                                                                    </div>
                                                                </div>
                                                                <div className='flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b gap-4'>
                                                                    <button
                                                                        className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'
                                                                        type='submit'>
                                                                        <i class="fas fa-check-circle"></i>   aceptar
                                                                    </button>
                                                                </div>

                                                            </form>
                                                        ) :
                                                            this.state.checked2 ?
                                                                <form onSubmit={this.GenerarDescuentoCantidad}>
                                                                    <div className="mx-2">
                                                                        <div className='relative flex-auto mx-8'>
                                                                            <div class="mb-6">
                                                                                <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Cantidad</label>
                                                                                <input type="text" name="cantidad" onChange={this.inputChange} value={this.state.cantidad}
                                                                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="50" required />
                                                                            </div>
                                                                            <div class="mb-6">
                                                                                <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Descripción</label>
                                                                                <textarea type="text" name="descripcion_cantidad" value={this.state.descripcion_cantidad}
                                                                                    onChange={this.inputChange} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="descrpción" rows="3" required />
                                                                            </div>
                                                                            <div class="mb-6">
                                                                                <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Concepto</label>
                                                                                <input type="text" name="concepto_cantidad" value={this.state.concepto_cantidad}
                                                                                    onChange={this.inputChange} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="descuento" required />
                                                                            </div>
                                                                            <div class="grid grid-cols-12 w-full mb-1 text-center">
                                                                                <div className="col-span-4">

                                                                                </div>

                                                                                <div className="border border-gray-700 mx-2 col-span-4 bg-gray-400">
                                                                                    <span>Mensualidad:</span>

                                                                                </div>
                                                                                <div className="border border-gray-700 col-span-4 bg-gray-400">
                                                                                    <div className=" mx-20 text-right">
                                                                                        <span>$ {this.state.pago.Costo_Pago}</span>
                                                                                    </div>

                                                                                </div>

                                                                            </div>
                                                                            <div class="grid grid-cols-12 w-full mb-1 text-center">
                                                                                <div className="col-span-4">

                                                                                </div>

                                                                                <div className="border border-gray-700 mx-2 col-span-4 bg-gray-400">
                                                                                    <span>Descuento anterior:</span>

                                                                                </div>
                                                                                <div className="border border-gray-700 col-span-4 bg-gray-400">
                                                                                    <div className=" mx-20 text-right">
                                                                                        <span>$ {this.state.pago.Descuentos}</span>
                                                                                    </div>

                                                                                </div>

                                                                            </div>
                                                                            <div class="grid grid-cols-12 w-full mb-1 text-center">
                                                                                <div className="col-span-4">

                                                                                </div>

                                                                                <div className="border border-gray-700 mx-2 col-span-4 bg-gray-400">
                                                                                    <span>Descuento:</span>

                                                                                </div>
                                                                                <div className="border border-gray-700 col-span-4 bg-gray-400">
                                                                                    <div className=" mx-20 text-right">
                                                                                        <span>$ {this.state.descuento_cantidad}</span>
                                                                                    </div>

                                                                                </div>

                                                                            </div>

                                                                            <div class="grid grid-cols-12 w-full mb-1 text-center">
                                                                                <div className="col-span-4">

                                                                                </div>

                                                                                <div className="border border-gray-700 mx-2 col-span-4 bg-gray-400">
                                                                                    <span>Total:</span>

                                                                                </div>
                                                                                <div className="border border-gray-700 col-span-4 bg-gray-400">
                                                                                    <div className=" mx-20 text-right">
                                                                                        <span>$ {this.state.total_mensual}</span>
                                                                                    </div>

                                                                                </div>

                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                    <div className='flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b gap-4'>
                                                                        <button
                                                                            className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'
                                                                            type='submit'>
                                                                            <i class="fas fa-check-circle"></i>   aceptar
                                                                        </button>
                                                                    </div>
                                                                </form>
                                                                :

                                                                <div class="text-center py-2 lg:px-2">
                                                                    <div class="p-2 bg-indigo-800 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex" role="alert">
                                                                        <span class="flex rounded-full bg-indigo-500 uppercase px-2 py-1 text-xs font-bold mr-3"><i class="fas fa-info-circle"></i></span>
                                                                        <span class="font-semibold mr-2 text-left flex-auto">Seleccione un método de descuento</span>
                                                                        <i class="fas fa-angle-double-up h-4 w-4"></i>
                                                                    </div>
                                                                </div>
                                                        }


                                                    </div>


                                                    {/*footer*/}
                                                    <div className='flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b gap-4'>


                                                        <button
                                                            className='text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'
                                                            type='button'
                                                            onClick={() => this.ModalDescuentoCerrar()}>
                                                            Cerrar
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
                                    </div>

                                </>
                            ) : null}


                            {this.state.modalPago ? (
                                <>
                                    <div div class="overflow-y-auto h-32">
                                        <div className='justify-center items-center fixed overflow-auto inset-0 z-50 outline-none focus:outline-none animate__animated animate__fadeIn'>
                                            <div className='relative w-auto my-6 mx-auto max-w-3xl'>
                                                {/*content*/}
                                                <div className='rounded-3xl relative flex flex-col w-full bg-base outline-none focus:outline-none shadow-sm2 z-20'>
                                                    {/*header*/}
                                                    <div className=' rounded-t-3xl'>
                                                        <p class='text-primary text-3xl text-center my-5 font-bold uppercase'>Detalles del pago</p>
                                                    </div>
                                                    {/*body*/}
                                                    <div className='relative flex-auto '>
                                                        <div class='grid grid-cols-2 gap-5 px-6 py-5 '>
                                                            <div>
                                                                <p class="mb-2 font-bold text-primary text-lg">Fecha pagado: </p>
                                                                <p class="mb-2 font-semibold text-secondary text-lg">{this.state.fecha}</p>
                                                            </div>

                                                            <div>
                                                                <p class="mb-2 font-bold text-primary text-lg ">Cantidad pagado: </p>
                                                                <p class="mb-2 font-semibold text-secondary text-lg">{this.state.cantidad}</p>
                                                            </div>
                                                        </div>

                                                        <p class="ml-10 mb-2 font-bold text-primary text-lg ">Comprobante de pago </p>
                                                        <iframe src={this.state.comprobante_pago}> </iframe>

                                                    </div>
                                                    {/*footer*/}
                                                    <div className='flex items-center justify-end p-1 border-t border-solid border-blueGray-200 rounded-b'>
                                                        <button
                                                            className='text-black-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                                                            type='button'
                                                            onClick={() => this.setState({ modalPago: false })}>
                                                            Cerrar
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div onClick={() => this.setState({ modalPago: false })} className='opacity-25 fixed inset-0 cursor-pointer bg-black'></div>
                                        </div>

                                    </div>
                                </>
                            ) : null}



                        </div>




                    </div>



                </div>


            </>
        );
    }

}



export default withRouter(GestionMensualidades);